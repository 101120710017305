import { OverlayService } from '../../../../../core/services/overlay.service';
import { Injectable } from '@angular/core';
import { LibraryHttpService } from './library-http.service';
import { ModalFeedbackComponent } from '../components/modal-feedback/modal-feedback.component';
import { ModalPodcastRenameComponent } from '../components/modal-podcast-rename/modal-podcast-rename.component';
import { RenamePodcastForm } from '../forms/rename-podcast.form';
import { IPodcast } from './library.types';
import { filter } from 'rxjs/operators';
import { ModalDeletePodcastComponent } from '../components/modal-delete-podcast/modal-delete-podcast.component';
import { switchMap } from 'rxjs';
import { PaginationForm } from '../../../../../shared/forms/pagination.form';
import { FilterTypeEnum } from '../components/library-filter-buttons/library-filter-buttons.component';
import { AudioService } from '../../../../../shared/components/player/services/audio.service';

@Injectable()
export class LibraryService {
  constructor(
    private readonly _overlayService: OverlayService,
    private readonly _libraryHttpService: LibraryHttpService,
    private readonly _renamePodcastForm: RenamePodcastForm,
    private readonly _paginationForm: PaginationForm,
    private readonly _audioService: AudioService
  ) {}

  getPodcastsList(refetchAfterDelete?: boolean) {
    return this._libraryHttpService.queryPodcastsList(
      this.preparePaginationData(refetchAfterDelete)
    );
  }

  deletePodcast(podcast: IPodcast | null) {
    const overlayRef = this._overlayService.open({ position: 'cc' }, ModalDeletePodcastComponent);

    overlayRef.closedEvent
      .pipe(
        filter(value => !!value),
        switchMap(() => {
          podcast?.id === this._audioService.podcast$.value?.id &&
            this._audioService.podcast$.next(null);

          return this._libraryHttpService.deletePodcast().mutate(podcast?.id);
        })
      )
      .subscribe(() => this.getPodcastsList(true).refetch());
  }

  renamePodcast(podcast: IPodcast | null) {
    this._renamePodcastForm.vm.patchValue({ title: podcast?.title, id: podcast?.id });

    const overlayRef = this._overlayService.open({ position: 'cc' }, ModalPodcastRenameComponent);

    overlayRef.closedEvent
      .pipe(
        filter(value => !!value),
        switchMap(value => this._patchPodcastData(value))
      )
      .subscribe(() => this.getPodcastsList().refetch());
  }

  private _patchPodcastData(dataToUpdate: Partial<IPodcast>) {
    return this._libraryHttpService.renamePodcast().mutate(dataToUpdate);
  }

  sendFeedback() {
    this._overlayService.open({ position: 'cc' }, ModalFeedbackComponent);
  }

  preparePaginationData(refetchAfterDelete = false) {
    const paginationData = this._paginationForm.vm.getRawValue();

    if (paginationData.allPageResults === 1 && refetchAfterDelete && paginationData.page > 1) {
      const newPage = paginationData.page - 1;
      this._paginationForm.vm.patchValue({ page: newPage }, { emitEvent: false });
      paginationData.page = newPage;
    }

    let filters = `limit=${paginationData.perPage}&page=${paginationData.page}&sort=${paginationData.sort}`;

    paginationData.search &&
      (filters += `&filter[title][_icontains]=%5C${encodeURIComponent(paginationData.search)}`);

    paginationData.filter === FilterTypeEnum.STARTED &&
      (filters += `&filter[user_time][_gt]=0&filter[finished][_eq]=false`);

    paginationData.filter === FilterTypeEnum.FINISHED && (filters += `&filter[finished][_eq]=true`);

    return filters;
  }
}
