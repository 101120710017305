import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
  WritableSignal,
} from '@angular/core';
import { ButtonComponent } from '../../../../../../shared/components/button/button.component';
import { DatePipe, NgClass, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { IconComponent } from '../../../../../../shared/components/icon/icon.component';
import { IPodcast, IPodcastActionData } from '../../state/library.types';
import { RouterLink } from '@angular/router';
import { MenuActionTypeEnum, PodcastMenuComponent } from '../podcast-menu/podcast-menu.component';
import { LoaderComponent } from '../../../../../../shared/components/loader/loader.component';
import { PaginationComponent } from '../../../../../../shared/components/pagination/pagination.component';
import { FormGroup } from '@angular/forms';
import { NgVarDirective } from '../../../../../../shared/directives/ng-var.directive';
import { PodcastIconDirective } from '../../../../../../shared/directives/podcast-icon.directive';
import { STEPS } from '../../../../../../utils/slider-steps';

@Component({
  selector: 'app-library-list',
  standalone: true,
  templateUrl: './library-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ButtonComponent,
    NgForOf,
    NgClass,
    DatePipe,
    IconComponent,
    RouterLink,
    NgIf,
    PodcastMenuComponent,
    LoaderComponent,
    PaginationComponent,
    NgTemplateOutlet,
    NgVarDirective,
    PodcastIconDirective,
  ],
})
export class LibraryListComponent {
  @Input() data!: IPodcast[];
  @Input() loading!: boolean;
  @Input() paginationForm!: FormGroup;
  @Output() podcastActionEmitter: EventEmitter<IPodcastActionData> = new EventEmitter();

  selectedPodcast: WritableSignal<IPodcast | null> = signal(null);
  steps = STEPS;

  showPodcastMenu(event: Event, podcast: IPodcast) {
    event.stopPropagation();
    this.selectedPodcast.set(podcast === this.selectedPodcast() ? null : podcast);
  }

  onPodcastMenuAction(actionType: MenuActionTypeEnum) {
    actionType !== MenuActionTypeEnum.CLOSE &&
      this.podcastActionEmitter.emit({ podcast: this.selectedPodcast(), actionType });

    this.selectedPodcast.set(null);
  }
}
