import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoaderComponent } from '../../loader/loader.component';

@Component({
  selector: 'app-modal-loader',
  standalone: true,
  templateUrl: './modal-loader.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LoaderComponent],
})
export class ModalLoaderComponent {}
