import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBaseService } from '../../../../../core/bases/form-base.service';

export interface IRenamePodcastForm {
  title: FormControl<string>;
  id: FormControl<string>;
}

@Injectable({ providedIn: 'root' })
export class RenamePodcastForm extends FormBaseService<IRenamePodcastForm> {
  constructor() {
    super();
    this.form = new FormGroup<IRenamePodcastForm>({
      title: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      id: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }
}
