import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { SafePipe } from '../pipes/safe.pipe';

@Directive({
  selector: '[podcastIcon]',
  standalone: true,
  providers: [SafePipe],
})
export class PodcastIconDirective {
  @Input()
  set podcastUrl(value: any) {
    this._checkFavicon(value);
  }

  constructor(private readonly _elementRef: ElementRef, private readonly _renderer: Renderer2) {}

  private _checkFavicon(url: string) {
    let domain;

    const urlParts = url.split('/');
    const domainPart = urlParts[2];

    const domainParts = domainPart.split(':');
    domain = domainParts[0];

    this.checkFaviconAvailability(domain);
  }

  checkFaviconAvailability(url: string): void {
    const favicon = this._renderer.createElement('img');
    const faviconUrl = 'http://www.google.com/s2/favicons?domain=' + url + '&sz=32';

    this._renderer.setAttribute(favicon, 'src', faviconUrl);

    favicon.onload = () => {
      this._renderer.setAttribute(this._elementRef.nativeElement, 'src', faviconUrl);
    };

    favicon.onerror = () => {
      this._renderer.setAttribute(
        this._elementRef.nativeElement,
        'src',
        'assets/icons/ilisten.svg'
      );
    };
  }
}
