import { Routes } from '@angular/router';
import { AuthLayoutComponent } from './_layout/layout';
import { authGuard } from '../../core/guards/auth.guard';

export const AUTH_ROUTES: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        canMatch: [authGuard({ isProtected: false, redirectTo: ['/'] })],
        loadComponent: () => import('./pages/index/index.component').then(x => x.IndexComponent),
      },
      {
        path: 'register',
        canMatch: [authGuard({ isProtected: false, redirectTo: ['/'] })],
        loadComponent: () =>
          import('./pages/register/register.component').then(x => x.RegisterComponent),
      },
      {
        path: 'callback',
        canMatch: [authGuard({ isProtected: false, redirectTo: ['/'] })],
        loadComponent: () => import('./pages/index/index.component').then(x => x.IndexComponent),
      },
      {
        path: 'forgot-password',
        canMatch: [authGuard({ isProtected: false, redirectTo: ['/'] })],
        loadComponent: () =>
          import('./pages/forgot-password/forgot-password.component').then(
            x => x.ForgotPasswordComponent
          ),
      },
      {
        path: 'email-confirm',
        loadComponent: () =>
          import('./pages/email-confirm/email-confirm.component').then(
            x => x.EmailConfirmComponent
          ),
      },
      {
        path: 'email-confirm/status',
        loadComponent: () =>
          import('./pages/email-confirm-status/email-confirm-status.component').then(
            x => x.EmailConfirmStatusComponent
          ),
      },
      {
        path: 'set-password',
        canMatch: [authGuard({ isProtected: false, redirectTo: ['/'] })],
        loadComponent: () =>
          import('./pages/set-password/set-password.component').then(x => x.SetPasswordComponent),
      },
      {
        path: 'set-password/success',
        canMatch: [authGuard({ isProtected: false, redirectTo: ['/'] })],
        loadComponent: () =>
          import('./pages/set-password-success/set-password-success.component').then(
            x => x.SetPasswordSuccessComponent
          ),
      },
      { path: '**', redirectTo: '' },
    ],
  },
];
