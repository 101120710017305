import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { InputBaseComponent } from '../../../core/bases/input-base.component';
import { FormsModule } from '@angular/forms';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { NgVarDirective } from '../../directives/ng-var.directive';
import { distinctUntilChanged, Subject, takeUntil, tap } from 'rxjs';
import { STEPS } from '../../../utils/slider-steps';

@Component({
  selector: 'app-slider',
  standalone: true,
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  imports: [FormsModule, NgForOf, IconComponent, NgClass, NgVarDirective, NgIf],
})
export class SliderComponent
  extends InputBaseComponent<number>
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('tooltip') tooltip!: ElementRef;
  @ViewChild('rangeInput') rangeInput!: ElementRef;

  @Input() step = 1;
  @Input() minCount = 1;
  @Input() set maxCountSetter(value: number) {
    this.maxCount = value;
    this._prepareMarks();
  }
  @Input() mode: 'slider' | 'player' = 'slider';

  @Output() manualChangeEmitter = new EventEmitter<number>();
  @Output() mouseDownEmitter = new EventEmitter<void>();
  @Output() mouseUpEmitter = new EventEmitter<void>();

  marksArray: number[] = [];
  maxCount = 40;
  steps = STEPS;
  private readonly _destroy$ = new Subject<boolean>();
  ngOnInit() {
    this._controlListener();
  }

  ngAfterViewInit() {
    this.handleChange(this.value);
  }

  private _prepareMarks() {
    this.marksArray = Array(this.maxCount - (this.minCount - 1))
      .fill(0)
      .filter((x, i) => i === 0 || (i + 1) % this.minCount === 0);
  }

  handleChange(value: number) {
    const trackSuccessBg = this.mode === 'player' ? '#303031' : '#55cf96';
    const trackBg = this.mode === 'player' ? '#ccc' : '#C3F1DDFF';

    const newValue = Number(((value - this.minCount) * 100) / (this.maxCount - this.minCount)),
      newPosition = 10 - newValue * (14 / 100);
    this.mode !== 'player' &&
      (this.tooltip.nativeElement.style.left = `calc(${newValue}% + (${newPosition - 10}px))`);

    const range = this.maxCount - this.minCount;
    const progress = ((value - this.minCount) / range) * 100;

    this.rangeInput.nativeElement.style.background = `linear-gradient(to right, ${trackSuccessBg} ${progress}%, ${trackBg} ${progress}%)`;
  }

  private _controlListener() {
    this.ngControl.control?.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap(value => this.handleChange(value)),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  onManualChange(value: number) {
    this.manualChangeEmitter.emit(value);
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
