import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalCloseComponent } from '../../../../../../shared/components/modal/modal-close/modal-close.component';
import { ModalDialogButtonsComponent } from '../../../../../../shared/components/modal/modal-dialog-buttons/modal-dialog-buttons.component';
import { CustomOverlayRef } from '../../../../../../core/services/overlay.service';

@Component({
  selector: 'app-modal-active-subscription',
  standalone: true,
  imports: [ModalCloseComponent, ModalDialogButtonsComponent],
  templateUrl: './modal-active-subscription.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalActiveSubscriptionComponent {
  constructor(public dialogRef: CustomOverlayRef) {}
}
