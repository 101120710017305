<div class="text-center flex flex-col items-center">
  <ng-container>
    <h3 class="text-primary-500 text-3xl font-Merriweather pb-8">
      {{ infoData?.title || 'Oops!' }}
    </h3>
    <p
      class="pb-[50px] max-w-[470px] text-center"
      [ngClass]="infoData?.subtitle ? 'text-sm' : 'text-xl'"
    >
      {{ infoData?.subtitle || 'Something went wrong.' }}
    </p>
    <app-button (click)="onButtonClick()" class="w-[225px] mb-[10%]"
      ><span>{{ infoData?.button || 'Back to home' }}</span></app-button
    >
  </ng-container>
</div>
