import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToMinutes',
  standalone: true,
})
export class SecondsToMinutesPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const minutes: number = Math.floor(value / 60);
    const seconds: number = value - minutes * 60;
    const displayMinutes: string = minutes < 10 ? '0' + minutes : '' + minutes;
    const displaySeconds: string =
      seconds < 10 ? '0' + seconds.toFixed(0) : '' + seconds.toFixed(0);
    return `${displayMinutes}:${displaySeconds}`;
  }
}
