import { Routes, UrlMatchResult, UrlSegment } from '@angular/router';
import { DashboardLayoutComponent } from './_layout/layout';
import { LIBRARY_ROUTES } from './modules/library/library-routes';
import { authGuard } from '../../core/guards/auth.guard';
import { DASHBOARD_HOME_ROUTES } from './modules/dashboard-home/dashboard-home-routes';
import { SETTINGS_ROUTES } from './modules/settings/settings-routes';

export const DASHBOARD_ROUTES: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    canMatch: [authGuard({ isProtected: true, redirectTo: ['/auth'] })],
    children: [
      {
        path: 'home',
        children: DASHBOARD_HOME_ROUTES,
      },
      {
        path: 'settings',
        children: SETTINGS_ROUTES,
      },
      {
        path: 'library',
        children: LIBRARY_ROUTES,
      },
      {
        matcher: purchaseStatusMatcher,
        loadComponent: () =>
          import('./components/stripe-purchase-status/stripe-purchase-status.component').then(
            x => x.StripePurchaseStatusComponent
          ),
      },
      { path: '**', redirectTo: 'home' },
    ],
  },
];

function purchaseStatusMatcher(segments: UrlSegment[]): UrlMatchResult | null {
  const path = segments.map(segment => segment.path).join('/');

  const stripePathRegex =
    /^(stripe-purchase-success|stripe-purchase-error|stripe-subscription-cancelled|inactive-subscription)$/;

  return stripePathRegex.test(path)
    ? {
        consumed: segments,
        posParams: { path: new UrlSegment(path, {}) },
      }
    : null;
}
