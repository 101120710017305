import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class FormBaseService<T extends { [K in keyof T]: AbstractControl<any, any> }> {
  protected form!: FormGroup<T>;

  get vm() {
    return this.form;
  }

  canSubmitForm(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false;
    }
    return true;
  }

  resetForm(): void {
    this.form.reset();
  }
}
