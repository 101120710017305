<div class="inline-block py-3" *ngVar="paginationForm.controls as controls">
  <div *ngVar="controls.page.value as currentPage">
    <nav
      class="flex gap-1 items-center"
      *ngVar="controls.totalPages.value as totalPages"
      [ngClass]="{ hidden: !totalPages }"
    >
      <app-pagination-button
        [disabled]="currentPage === 1"
        (click)="updatePaginationForm(currentPage - 1)"
        class="mr-2"
        [ngClass]="{ 'pointer-events-none': currentPage === 1 }"
      >
        <app-icon name="pagination-arrow" class="text-xl"></app-icon>
      </app-pagination-button>

      <app-pagination-button *ngIf="currentPage !== 1" (click)="updatePaginationForm(1)"
        >1</app-pagination-button
      >

      <div *ngIf="currentPage > 3 && totalPages > 3">...</div>

      <app-pagination-button
        *ngIf="currentPage > 2 && totalPages > 2"
        (click)="updatePaginationForm(currentPage - 1)"
      >
        {{ currentPage - 1 }}
      </app-pagination-button>

      <app-pagination-button
        [active]="true"
        [disabled]="!!currentPage"
        [ngClass]="{ 'pointer-events-none': !!currentPage }"
      >
        {{ currentPage }}
      </app-pagination-button>

      <app-pagination-button
        *ngIf="currentPage < totalPages - 1 && totalPages > 2"
        (click)="updatePaginationForm(currentPage + 1)"
      >
        {{ currentPage + 1 }}
      </app-pagination-button>

      <div *ngIf="currentPage <= totalPages - 3">...</div>

      <app-pagination-button
        *ngIf="currentPage !== totalPages && totalPages > 1"
        (click)="updatePaginationForm(totalPages)"
      >
        {{ totalPages }}
      </app-pagination-button>

      <app-pagination-button
        class="rotate-180 ml-2"
        [disabled]="currentPage === totalPages || totalPages <= 1"
        (click)="updatePaginationForm(currentPage === totalPages ? 0 : currentPage + 1)"
        [ngClass]="{ 'pointer-events-none': currentPage === totalPages || totalPages <= 1 }"
      >
        <app-icon name="pagination-arrow" class="text-xl"></app-icon>
      </app-pagination-button>
    </nav>
  </div>
</div>
