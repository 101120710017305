<div class="flex w-full min-h-full bg-white">
  <div class="hidden overflow-x-hidden items-center pr-20 w-7/12 min-h-full bg-blue-200 md:flex">
    <img
      [src]="'assets/img/onboarding-2' | webpSupport"
      alt="iListen extension"
      class="object-cover object-right max-h-[690px] h-[80vh]"
    />
  </div>
  <div class="p-6 w-full flex flex-col justify-center md:w-5/12">
    <img
      class="w-[250px] h-[62px] mb-[40px] mx-auto"
      src="assets/icons/ilisten-logo.svg"
      alt="iListen logo"
    />
    <router-outlet />
  </div>
</div>
