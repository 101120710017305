import { Injectable } from '@angular/core';
import { PlayingHttpService } from './playing-http.service';

@Injectable()
export class PlayingService {
  constructor(private readonly _playingHttpService: PlayingHttpService) {}

  getPodcast(id: string) {
    return this._playingHttpService.podcastSummaryQuery(id).result$;
  }
}
