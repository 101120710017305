import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { SafePipe } from '../../pipes/safe.pipe';
import { AudioService } from './services/audio.service';
import { AsyncPipe, DatePipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { NgVarDirective } from '../../directives/ng-var.directive';
import { IconComponent } from '../icon/icon.component';
import { SliderComponent } from '../slider/slider.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AudioPlayerForm } from './forms/audio-player.form';
import { SecondsToMinutesPipe } from '../../pipes/seconds-to-minutes.pipe';
import { Subject, switchMap, takeUntil, tap } from 'rxjs';
import { PlayingHttpService } from '../../../modules/dashboard/modules/library/state/playing-http.service';
import { filterSuccess } from '@ngneat/query';
import { filter } from 'rxjs/operators';
import { PodcastIconDirective } from '../../directives/podcast-icon.directive';
import { PodcastDataService } from '../../services/podcast-data.service';
import { RouterLink } from '@angular/router';

export enum GetPodcastModeEnum {
  PREVIOUS,
  NEXT,
  SHUFFLE,
}

const PLAYBACK_SPEED = [0.5, 0.7, 1, 1.2, 1.5];

@Component({
  selector: 'app-player',
  standalone: true,
  imports: [
    SafePipe,
    AsyncPipe,
    NgVarDirective,
    NgIf,
    IconComponent,
    DatePipe,
    SliderComponent,
    ReactiveFormsModule,
    SecondsToMinutesPipe,
    NgClass,
    NgForOf,
    PodcastIconDirective,
    RouterLink,
  ],
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayerComponent implements OnInit, OnDestroy {
  @ViewChild('player') private _player!: ElementRef<HTMLAudioElement>;

  formVM = this._audioPlayerForm.vm;
  maxCount = 0;
  podcast$ = this._audioService.podcast$;
  audioLength = 0;
  previousTime = 0;

  shuffleActive = signal(false);
  timeChangeActive = signal(false);
  loopPlaybackActive = signal(false);
  showPlaybackSpeedMenu = signal(false);

  newPodcastAction$ = new Subject<GetPodcastModeEnum>();
  readonly playbackSpeed = PLAYBACK_SPEED;
  readonly getPodcastModeEnum = GetPodcastModeEnum;
  readonly iOSUserAgent = window.navigator.userAgent.includes('iPhone');
  private _autoSaveInterval!: number;
  private readonly _destroy$ = new Subject<boolean>();

  constructor(
    private readonly _audioService: AudioService,
    private readonly _audioPlayerForm: AudioPlayerForm,
    private readonly _playingHttpService: PlayingHttpService,
    private readonly _podcastDataService: PodcastDataService
  ) {}

  ngOnInit() {
    this._newPodcastListener();
    this._autoSave();
  }

  play(play: boolean) {
    if (play) {
      this._player.nativeElement.play();
    } else {
      this._player.nativeElement.pause();
      this._saveLastAudioTime();
    }
  }

  onLoad() {
    const playerElement = this._player.nativeElement;

    this.maxCount = Math.ceil(playerElement.duration);
    this.formVM.controls.volume.setValue(playerElement.volume, { emitEvent: false });
    playerElement.currentTime = this.podcast$.value?.user_time || 0;
    playerElement.playbackRate = this.podcast$.value?.playback_speed || 1;
    this.audioLength = Math.ceil(playerElement.duration);
    this.volumeListener();
    playerElement.currentTime < this.maxCount && this._player.nativeElement.play();
  }

  onTimeUpdate() {
    const newTime = Math.ceil(this._player.nativeElement.currentTime);

    if (this.timeChangeActive() || this.previousTime === newTime) return;

    this.previousTime = newTime;

    this.formVM.controls.audio.patchValue(newTime);
  }

  onAudioEnded() {
    this._saveLastAudioTime();
    if (this.loopPlaybackActive() || this.podcast$.value?.finished) return;

    this._podcastDataService.savePodcastData('FINISHED');

    this.newPodcastAction$.next(
      this.shuffleActive() ? GetPodcastModeEnum.SHUFFLE : GetPodcastModeEnum.NEXT
    );
  }

  onChangeCurrentTime(value: number) {
    this.timeChangeActive.set(true);
    this._player.nativeElement.currentTime = value;
    this.formVM.controls.audio.patchValue(value, { emitEvent: false });
    this._saveLastAudioTime();
    setTimeout(() => {
      this.timeChangeActive.set(false);
    }, 200);
  }

  onLoopPlayback() {
    if (this.loopPlaybackActive()) {
      this._player.nativeElement.removeAttribute('loop');
      this.loopPlaybackActive.set(false);
    } else {
      this._player.nativeElement.setAttribute('loop', 'true');
      this.loopPlaybackActive.set(true);
    }
  }

  onShuffle() {
    this.shuffleActive.set(!this.shuffleActive());
  }

  private _newPodcastListener() {
    this.newPodcastAction$
      .pipe(
        filter(() => !!this.podcast$.value?.id),
        tap(() => this._saveLastAudioTime()),
        switchMap(mode =>
          this._playingHttpService
            .getNewPodcast(
              this.shuffleActive() ? GetPodcastModeEnum.SHUFFLE : mode,
              this.podcast$.value?.id || ''
            )
            .result$.pipe(filterSuccess())
        ),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  volumeListener() {
    this.formVM.controls?.volume.valueChanges
      .pipe(
        tap(value => (this._player.nativeElement.volume = value)),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  onPlaybackSpeedChange(value: number) {
    this._player.nativeElement.playbackRate = value;
    this._podcastDataService.savePodcastData('SPEED', { playback_speed: value });
    this.showPlaybackSpeedMenu.set(false);
  }

  onPlaybackSpeedClick() {
    this.showPlaybackSpeedMenu.set(!this.showPlaybackSpeedMenu());
  }

  private _saveLastAudioTime() {
    this._podcastDataService.savePodcastData('TIME');
  }

  private _autoSave() {
    this._autoSaveInterval = setInterval(() => {
      !this._player?.nativeElement.paused && this._saveLastAudioTime();
    }, 5000);
  }

  ngOnDestroy() {
    clearInterval(this._autoSaveInterval);
    this.podcast$.next(null);
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  @HostListener('document:click', ['event'])
  handleClick() {
    this.showPlaybackSpeedMenu() && this.showPlaybackSpeedMenu.set(false);
  }
}
