<button
  class="rounded-full border-0 shadow-primary px-2 h-[35px] w-[35px] flex justify-center items-center hover:bg-secondary-500 hover:text-black"
  [disabled]="disabled"
  [ngClass]="{
    'bg-secondary-500': disabled,
    '!bg-primary-500 text-white hover:text-white hover:bg-primary-500': active,
  }"
>
  <ng-content />
</button>
