import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IPodcast } from '../../state/library.types';
import { DatePipe, NgForOf } from '@angular/common';
import { IconComponent } from '../../../../../../shared/components/icon/icon.component';
import { SafePipe } from "../../../../../../shared/pipes/safe.pipe";

@Component({
  selector: 'app-playing-article-summary',
  standalone: true,
  templateUrl: './playing-article-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgForOf, IconComponent, DatePipe, SafePipe],
})
export class PlayingArticleSummaryComponent {
  @Input() podcast!: IPodcast;
}
