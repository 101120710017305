<div
  class="cursor-pointer relative w-full h-[45px] box-border border-[1px] border-gray-500 flex bg-white rounded-[30px]"
>
  <div
    class="absolute rounded-[30px] px-4 flex items-center top-0 w-full text-gray-900 h-full overflow-hidden text-ellipsis whitespace-nowrap select-none text-sm"
    (click)="$event.stopPropagation(); onShowItemsList()"
    [ngSwitch]="queryStatus"
  >
    <app-loader class="w-1/2 mx-auto" *ngSwitchCase="'loading'" />
    <ng-container
      *ngSwitchDefault
      [ngTemplateOutlet]="theme === selectThemeEnum.SPEED ? optionSpeed : optionRegular"
      [ngTemplateOutletContext]="{ item: ngControl.control!.value, isOption: false }"
    />
  </div>
  <div
    class="absolute top-[45px] right-0 rounded-lg overflow-hidden z-50 bg-transparent"
    [ngClass]="{ 'w-full': theme !== selectThemeEnum.SPEED }"
  >
    <div
      class="max-h-[150px] bg-white border-gray-500 overflow-y-auto overflow-x-hidden"
      *ngIf="(selectedDropdown$ | async) === id"
    >
      <div
        class="py-3 px-2 hover:bg-gray-200 w-full select-none flex items-center text-sm"
        *ngFor="let item of options"
        (click)="$event.stopPropagation(); onSelectItem(item)"
      >
        <ng-container
          [ngTemplateOutlet]="theme === selectThemeEnum.SPEED ? optionSpeed : optionRegular"
          [ngTemplateOutletContext]="{ item: item, isOption: true }"
        />
      </div>
    </div>
  </div>
</div>

<ng-template #optionRegular let-item="item" let-isOption="isOption">
  <div
    class="pr-2 mr-2 border-r-[1px] border-black"
    *ngIf="item?.icon || (item?.audio && !isOption)"
  >
    <ng-container *ngIf="item?.icon; else voiceIcon">
      <img
        class="min-w-[20px] w-[20px] h-[20px] rounded-full shadow-secondary"
        [src]="item?.audio ? '/assets/svg/voice.svg' : item.icon"
      />
    </ng-container>
  </div>

  <span class="truncate">{{ item?.key }}</span>

  <ng-container *ngIf="item?.audio && isOption">
    <div
      (click)="
        $event.stopPropagation();
        playAudio(item.value !== activeSampleId(), item?.audio, item.value)
      "
      class="p-2 hover:bg-gray-100 rounded-full ml-auto cursor-pointer"
    >
      <app-icon *ngIf="item.value !== activeSampleId()" name="play" class="text-xs"></app-icon>
      <app-icon *ngIf="item.value === activeSampleId()" name="pause" class="text-xs"></app-icon>
    </div>
  </ng-container>

  <app-icon
    *ngIf="!isOption"
    [name]="rightIcon || 'arrow-left'"
    class="ml-auto text-xl"
    [ngClass]="{ '-rotate-90': !rightIcon }"
  ></app-icon>
</ng-template>

<ng-template #optionSpeed let-item="item" let-isOption="isOption">
  <span class="truncate" *ngIf="!isOption">Playback speed</span>

  <div class="text-center inline-block ml-auto mr-1" [ngClass]="{ 'px-3': isOption }">
    <img src="assets/svg/speed.svg" />
    <span class="block -mt-[2px] text-sm">{{ item.value }}x</span>
    <img src="assets/svg/speed.svg" />
  </div>
</ng-template>

<ng-template #voiceIcon>
  <img class="min-w-[20px] w-[20px] h-[20px]" [src]="'/assets/svg/voice.svg'" />
</ng-template>
