<section class="bg-white rounded-[4px] px-[45px] py-[40px] w-screen max-w-[490px] relative">
  <app-modal-close (click)="dialogRef.close()"></app-modal-close>
  <h3 class="text-center max-w-[230px] mx-auto mb-8 text-xl">Change email</h3>
  <form [formGroup]="formVM" (ngSubmit)="changeEmail()">
    <app-input-text
      class="mb-4"
      id="email"
      formControlName="email"
      label="New email"
    ></app-input-text>

    <app-input-text
      id="confirmEmail"
      formControlName="confirmEmail"
      label="Confirm new email"
    ></app-input-text>

    <app-button class="mt-[120px]" type="submit" [fullWidth]="true">Change email</app-button>
  </form>
</section>
