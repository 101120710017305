<ng-container
  *ngIf="{
    subscription: subscriptionQuery.result$ | async,
    invoicesQuery: invoicesQuery$ | async
  } as vm"
  [ngSwitch]="vm.subscription?.status"
>
  <section
    class="text-xl"
    *ngIf="vm.subscription?.status === 'success' && !vm.subscription?.isFetching"
  >
    <h2>Your subscription plan{{ (vm.subscription?.data?.length || 0) > 1 ? 's' : '' }}</h2>

    <div
      class="flex justify-between my-[30px] max-w-[380px]"
      *ngFor="let plan of vm.subscription?.data"
    >
      <p class="flex flex-col gap-1">
        <span class="font-Merriweather inline-block">
          {{ plan.name }}
        </span>

        <span class="text-xs"
          >{{ plan?.subscriptionDetails?.generatedPodcasts }}/{{
            plan?.subscriptionDetails?.podcastsLimit
          }}
          podcasts have been generated</span
        >

        <span class="text-xs"
          >Reset date:
          {{ plan?.subscriptionDetails?.timePeriod?.endDate! | date : 'hh:mm dd.MM.yyyy' }}
        </span>
      </p>

      <app-icon
        *ngIf="vm.subscription?.data"
        (click)="deleteSubscriptionPlan(plan.stripe_subscription_id || '')"
        class="cursor-pointer text-2xl"
        name="delete"
      ></app-icon>
    </div>

    <span class="font-Merriweather inline-block my-[30px]" *ngIf="!vm.subscription?.data?.length"
      >You don't have an active subscription</span
    >

    <app-button (click)="onChangePlanButton()" [fullWidth]="true" class="max-w-[290px]"
      >{{ vm.subscription?.data?.length ? 'Change' : 'Choose' }} your plan</app-button
    >

    <a
      *ngIf="vm.invoicesQuery?.data?.portalUrl"
      target="_blank"
      [href]="vm.invoicesQuery?.data?.portalUrl"
      class="inline-block mt-[30px] text-sm text-[#4285F4]"
      >Your invoices</a
    >
  </section>

  <div
    class="flex justify-center h-full mt-[30%]"
    *ngIf="vm.subscription?.status !== 'success' || vm.subscription?.isFetching"
  >
    <app-info *ngIf="vm.subscription?.status === 'error'; else loader"></app-info>
  </div>
</ng-container>

<ng-template #loader>
  <app-loader class="max-w-[200px]"></app-loader>
</ng-template>
