import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBaseService } from '../../../../../core/bases/form-base.service';
import { email, matchValues } from '../../../../../utils/validators';

export interface IChangeEmailForm {
  email: FormControl<string>;
  confirmEmail: FormControl<string>;
}

@Injectable({ providedIn: 'root' })
export class ChangeEmailForm extends FormBaseService<IChangeEmailForm> {
  constructor() {
    super();
    this.form = new FormGroup<IChangeEmailForm>(
      {
        email: new FormControl('', {
          nonNullable: true,
          validators: [Validators.required, email],
        }),
        confirmEmail: new FormControl('', {
          nonNullable: true,
          validators: [Validators.required, email],
        }),
      },
      { validators: [matchValues('email', 'confirmEmail', 'email')] }
    );
  }
}
