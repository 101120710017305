import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IconComponent } from '../../icon/icon.component';

@Component({
  standalone: true,
  selector: 'app-modal-close',
  templateUrl: './modal-close.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconComponent],
})
export class ModalCloseComponent {}
