import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormBaseService } from '../../core/bases/form-base.service';
import { FilterTypeEnum } from '../../modules/dashboard/modules/library/components/library-filter-buttons/library-filter-buttons.component';

export interface IPaginationForm {
  page: FormControl<number>;
  perPage: FormControl<number>;
  search: FormControl<string>;
  sort: FormControl<string>;
  totalPages: FormControl<number>;
  allPageResults: FormControl<number>;
  filter: FormControl<FilterTypeEnum>;
}

@Injectable({ providedIn: 'root' })
export class PaginationForm extends FormBaseService<IPaginationForm> {
  constructor() {
    super();
    this.form = new FormGroup<IPaginationForm>({
      page: new FormControl(1, { nonNullable: true }),
      perPage: new FormControl(10, { nonNullable: true }),
      search: new FormControl('', { nonNullable: true }),
      sort: new FormControl('-date_created', { nonNullable: true }),
      totalPages: new FormControl(0, { nonNullable: true }),
      allPageResults: new FormControl(0, { nonNullable: true }),
      filter: new FormControl(FilterTypeEnum.ALL, { nonNullable: true }),
    });
  }
}
