import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subject, takeUntil, tap } from 'rxjs';

@Directive({
  selector: '[hideElement]',
  standalone: true,
})
export class HideElementDirective implements OnInit, OnDestroy {
  @Input() elementId!: string;

  private _elementsToHide = this._activatedRoute.snapshot.firstChild?.data['elementsToHide'];
  private _elementIsHidden = false;
  private readonly _destroy$ = new Subject<boolean>();

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _elementRef: ElementRef,
    private readonly _renderer: Renderer2,
    private readonly _router: Router
  ) {}

  ngOnInit() {
    this._checkRouteData();
  }

  private _checkRouteData() {
    if (!this.elementId) return;

    this._checkIfElementIsAvailableToHide();

    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        tap(() => {
          this._elementsToHide = this._activatedRoute.snapshot.firstChild?.data['elementsToHide'];
        }),
        takeUntil(this._destroy$)
      )
      .subscribe(() => this._checkIfElementIsAvailableToHide());
  }

  private _checkIfElementIsAvailableToHide() {
    if (this._elementsToHide?.includes(this.elementId)) {
      this._setElementStyles(true);
    } else if (this._elementIsHidden) {
      this._setElementStyles(false);
    }
  }

  private _setElementStyles(hide: boolean) {
    hide
      ? this._renderer.setStyle(this._elementRef.nativeElement, 'display', 'none')
      : this._renderer.removeStyle(this._elementRef.nativeElement, 'display');

    this._elementIsHidden = hide;
  }

  ngOnDestroy() {
    this._setElementStyles(false);
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
