<ng-container *ngVar="{ podcastsListQuery: (podcastsListQuery$ | async) } as vm">
  <section
    class="h-full flex flex-col"
    *ngVar="
      vm.podcastsListQuery?.isRefetching || vm.podcastsListQuery?.status !== 'success' as showLoader
    "
  >
    <ng-container
      *ngIf="
        showLoader || vm.podcastsListQuery?.data?.data?.length || !initialProcessIsActive();
        else info
      "
    >
      <app-library-search-bar [searchControl]="formVM.controls.search"></app-library-search-bar>
      <app-library-filter-buttons
        [filterControl]="formVM.controls.filter"
      ></app-library-filter-buttons>
      <app-library-list
        (podcastActionEmitter)="onPodcastMenuAction($event)"
        [data]="vm.podcastsListQuery?.data?.data"
        [loading]="showLoader"
        [paginationForm]="formVM"
      ></app-library-list>
    </ng-container>
  </section>

  <ng-template #info>
    <app-info
      [infoData]="vm.podcastsListQuery?.data?.data?.length === 0 ? emptyInfo : null"
      class="mt-[25vh]"
    ></app-info>
  </ng-template>
</ng-container>
