import { Component, Input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgVarDirective } from '../../directives/ng-var.directive';
import { NgClass, NgIf } from '@angular/common';
import { ButtonComponent } from '../button/button.component';
import { PaginationButtonComponent } from '../pagination-button/pagination-button.component';

@Component({
  selector: 'app-pagination',
  standalone: true,
  templateUrl: './pagination.component.html',
  imports: [
    IconComponent,
    ReactiveFormsModule,
    NgVarDirective,
    NgIf,
    ButtonComponent,
    PaginationButtonComponent,
    NgClass,
  ],
})
export class PaginationComponent {
  @Input() paginationForm!: FormGroup;

  updatePaginationForm(page: number) {
    this.paginationForm.patchValue({ page });
  }
}
