<section
  class="text-center bg-white rounded-[20px] py-[112px] px-[100px] w-screen max-w-[490px] relative"
>
  <app-modal-close (click)="dialogRef.close()"></app-modal-close>
  <h3 class="text-center text-xl">
    Want to write a review?
    <span class="block">
      Contact us at <a href="mailto:support@ilisten.ai">support@ilisten.ai</a></span
    >
  </h3>
</section>
