import { Routes } from '@angular/router';
import { AUTH_ROUTES } from './auth/routes';
import { DASHBOARD_ROUTES } from './dashboard/dashboard-routes';
import { EmailConfirmGuard } from '../core/guards/email-confirm.guard';
import { HOME_ROUTES } from './home/home-routes';

export const APP_ROUTES: Routes = [
  { path: '', children: HOME_ROUTES },
  {
    path: 'email-confirm',
    canActivate: [EmailConfirmGuard()],
    loadComponent: () => import('./home/pages/home-page/home-page').then(x => x.HomePage),
  },
  { path: 'auth', children: AUTH_ROUTES },
  { path: 'app', children: DASHBOARD_ROUTES },
  { path: '**', redirectTo: '' },
];
