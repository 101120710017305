import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { WebpSupportPipe } from '../../../shared/pipes/webp.pipe';
import { PageTitleService } from '../../../shared/services/page-title.service';

@Component({
  standalone: true,
  selector: 'iw-app-auth-layout',
  templateUrl: './layout.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, WebpSupportPipe],
})
export class AuthLayoutComponent implements OnInit {
  constructor(private readonly _pageTitleService: PageTitleService) {}

  ngOnInit() {
    this._pageTitleService.setTitle('iListen');
  }
}
