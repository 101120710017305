import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal } from '@angular/core';
import { LibrarySearchBarComponent } from '../../components/library-search-bar/library-search-bar.component';
import { LibraryListComponent } from '../../components/library-list/library-list.component';
import { ButtonComponent } from '../../../../../../shared/components/button/button.component';
import { IPodcast, IPodcastActionData } from '../../state/library.types';
import { AsyncPipe, NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';
import {
  InfoComponent,
  InfoData,
} from '../../../../../../shared/components/error-info/info.component';
import { MenuActionTypeEnum } from '../../components/podcast-menu/podcast-menu.component';
import { LibraryService } from '../../state/library.service';
import { LibraryHttpService } from '../../state/library-http.service';
import { LoaderComponent } from '../../../../../../shared/components/loader/loader.component';
import { NgVarDirective } from '../../../../../../shared/directives/ng-var.directive';
import { PaginationForm } from '../../../../../../shared/forms/pagination.form';
import { LibraryFilterButtonsComponent } from '../../components/library-filter-buttons/library-filter-buttons.component';
import {
  debounceTime,
  distinctUntilChanged,
  Observable,
  pairwise,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { QueryObserverResult } from '@tanstack/query-core/build/lib/types';
import { IOutput } from '../../../../../auth/state/auth.types';
import { filterSuccess } from '@ngneat/query';

const EMPTY_INFO: InfoData = {
  title: 'Nothing to see here...yet!',
  subtitle: 'Start by adding some content',
  button: 'Start',
  buttonUrl: '/app/home',
};

@Component({
  selector: 'app-podcast-library',
  standalone: true,
  templateUrl: './podcast-library.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LibrarySearchBarComponent,
    LibraryListComponent,
    ButtonComponent,
    NgIf,
    RouterLink,
    InfoComponent,
    AsyncPipe,
    LoaderComponent,
    NgVarDirective,
    LibraryFilterButtonsComponent,
  ],
  providers: [LibraryService, LibraryHttpService],
})
export class PodcastLibraryComponent implements OnInit, OnDestroy {
  formVM = this._paginationForm.vm;
  initialProcessIsActive = signal(true);
  podcastsListQuery$!: Observable<QueryObserverResult>;
  readonly emptyInfo = EMPTY_INFO;

  private readonly _destroy$ = new Subject<boolean>();

  constructor(
    private readonly _libraryService: LibraryService,
    private readonly _paginationForm: PaginationForm
  ) {}

  ngOnInit() {
    this._initVariables();
    this._initPaginationFormListener();
  }

  private _initVariables() {
    this.podcastsListQuery$ = this._libraryService.getPodcastsList().result$.pipe(
      filterSuccess(),
      tap(podcastsData => {
        this._updateTotalPages(podcastsData);
      })
    );
  }

  private _updateTotalPages(podcastsData: QueryObserverResult<IOutput<IPodcast[]>>) {
    const metaData = podcastsData?.data?.meta as { [key: string]: any };
    const totalPages = Math.ceil(metaData['filter_count'] / this.formVM.getRawValue().perPage);
    const allPageResults = podcastsData.data?.data.length || 0;

    metaData && this.formVM.patchValue({ totalPages, allPageResults }, { emitEvent: false });
  }

  onPodcastMenuAction(actionData: IPodcastActionData) {
    switch (actionData.actionType) {
      case MenuActionTypeEnum.DELETE:
        return this._libraryService.deletePodcast(actionData.podcast);
      case MenuActionTypeEnum.RENAME:
        return this._libraryService.renamePodcast(actionData.podcast);
      case MenuActionTypeEnum.FEEDBACK:
        return this._libraryService.sendFeedback();
    }
  }

  private _initPaginationFormListener() {
    this.formVM.valueChanges
      .pipe(
        startWith(this.formVM.getRawValue()),
        distinctUntilChanged(),
        debounceTime(300),
        pairwise(),
        tap(([prev, curr]) => {
          prev.page === curr.page && this.formVM.controls.page.setValue(1, { emitEvent: false });
        }),
        switchMap(() => this._libraryService.getPodcastsList().refetch()),
        takeUntil(this._destroy$)
      )
      .subscribe(() => this.initialProcessIsActive.set(false));
  }

  ngOnDestroy() {
    this._paginationForm.resetForm();
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
