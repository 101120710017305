<div
  class="relative max-w-[450px] w-full md:w-4/5 mx-auto flex p-[7px] rounded-[30px] text-xl"
  [ngClass]="theme === 'HOME' ? 'bg-white' : 'bg-gray-100'"
>
  <button
    (click)="onSelectPaymentTime(timeEnum.YEAR)"
    class="relative basis-1/2 py-3 rounded-[30px] z-[2] slide-transition--color"
    [ngClass]="{ 'text-white': selectedTime() === timeEnum.YEAR }"
  >
    Yearly
  </button>

  <button
    (click)="onSelectPaymentTime(timeEnum.MONTH)"
    class="relative basis-1/2 py-3 rounded-[30px] z-[2] slide-transition--color"
    [ngClass]="{ 'text-white': selectedTime() === timeEnum.MONTH }"
  >
    Monthly
  </button>

  <button
    class="absolute top-[7px] w-1/2 h-[52px] rounded-[30px] bg-primary-500 z-[1] slide-transition--position"
    [ngClass]="{
      'left-[7px]': selectedTime() === timeEnum.YEAR,
      'left-[calc(50%-7px)]': selectedTime() === timeEnum.MONTH
    }"
  ></button>
</div>

<div class="flex gap-[30px] justify-around md:justify-center mt-5 md:mt-20 flex-wrap">
  <app-subscription-card
    class="w-full max-w-[425px] min-h-full"
    *ngFor="let price of prices$ | async; let i = index"
    [data]="price"
    [index]="i"
    [theme]="theme"
    [freeTrialUnavailable]="freeTrialUnavailable"
    (planClickEmitter)="onPlanClick($event)"
  ></app-subscription-card>
</div>
