<section class="bg-white rounded-[20px] p-[35px] w-screen max-w-[490px] relative">
  <app-modal-close (click)="dialogRef.close()"></app-modal-close>
  <h3 class="text-center max-w-[230px] mx-auto mt-4 mb-3 text-xl">Rename</h3>
  <p class="text-center text-sm pb-6">Please enter a new name of the item</p>
  <form [formGroup]="formVM" (ngSubmit)="changePodcastName()">
    <app-input-text
      class="mb-10"
      id="podcastName"
      formControlName="title"
      placeholder="Enter a new name"
    ></app-input-text>

    <app-modal-dialog-buttons
      rightButtonType="submit"
      rightButtonText="Save"
    ></app-modal-dialog-buttons>
  </form>
</section>
