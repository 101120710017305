import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal } from '@angular/core';
import { NgClass, NgForOf } from '@angular/common';
import { IconComponent } from '../../../../shared/components/icon/icon.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AuthService } from '../../../auth/state/auth.service';
import { OverlayService } from '../../../../core/services/overlay.service';
import { ModalLogoutComponent } from '../../../auth/components/modal-logout/modal-logout.component';
import { ResolutionService } from '../../../../shared/services/resolution.service';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { PodcastDataService } from '../../../../shared/services/podcast-data.service';

export interface MenuItem {
  icon: string;
  name: string;
  url: string;
}

const MENU_ITEMS: MenuItem[] = [
  {
    icon: 'home',
    name: 'Home',
    url: '/home',
  },
  {
    icon: 'library',
    name: 'My Library',
    url: '/library',
  },
  {
    icon: 'settings',
    name: 'Settings',
    url: '/settings',
  },
];

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [NgForOf, IconComponent, RouterLink, RouterLinkActive, NgClass],
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit, OnDestroy {
  menuItems = MENU_ITEMS;
  showMenu = signal(false);
  device$ = this._resolutionService.getDeviceType();

  private readonly _destroy$ = new Subject<boolean>();

  constructor(
    private readonly _authService: AuthService,
    private readonly _overlayService: OverlayService,
    private readonly _podcastDataService: PodcastDataService,
    private readonly _resolutionService: ResolutionService
  ) {}

  ngOnInit() {
    this._deviceListener();
  }

  private _deviceListener() {
    this.device$.pipe(distinctUntilChanged(), takeUntil(this._destroy$)).subscribe(() => {
      this.showMenu.set(false);
    });
  }

  onMenuClick(showMenu?: boolean) {
    this.showMenu.set(showMenu || !this.showMenu());
  }

  logout(): void {
    const overlayRef = this._overlayService.open({ position: 'cc' }, ModalLogoutComponent);

    this._podcastDataService.savePodcastData('TIME');

    overlayRef.closedEvent.subscribe(value => {
      value && this._authService.logout();
    });
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
