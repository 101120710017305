<button routerLink="/app/settings" class="w-8 h-8 bg-gray-200 rounded-full hover:bg-gray-500">
  <app-icon name="arrow-left" class="text-3xl"></app-icon>
</button>
<section class="px-2.5 py-8 lg:py-[100px]" *ngVar="(invoicesQuery$ | async)?.data as invoices">
  <div class="max-w-[900px] w-full mx-auto flex flex-col gap-[30px]">
    <h2 class="font-Merriweather text-2xl md:text-5xl font-bold text-center mb-[60px]">
      Choose a pricing plan
    </h2>

    <app-subscription-options
      [freeTrialUnavailable]="!!invoices?.portalUrl"
      theme="SETTINGS"
    ></app-subscription-options>
  </div>
</section>
