import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalCloseComponent } from '../../../../../../shared/components/modal/modal-close/modal-close.component';
import { ModalDialogButtonsComponent } from '../../../../../../shared/components/modal/modal-dialog-buttons/modal-dialog-buttons.component';
import { CustomOverlayRef } from '../../../../../../core/services/overlay.service';

@Component({
  selector: 'app-modal-delete-podcast',
  standalone: true,
  templateUrl: './modal-delete-podcast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ModalCloseComponent, ModalDialogButtonsComponent],
})
export class ModalDeletePodcastComponent {
  constructor(public readonly dialogRef: CustomOverlayRef) {}
}
