import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { SubscriptionCardComponent } from '../subscription-card/subscription-card.component';
import { PricingService } from '../../../dashboard/modules/settings/state/pricing.service';
import { BehaviorSubject, Subject, takeUntil, tap } from 'rxjs';
import { IPrice } from '../../../../utils/pricing.types';
import { filterSuccess } from '@ngneat/query';

export enum TimeEnum {
  YEAR = 'year',
  MONTH = 'month',
}
@Component({
  selector: 'app-subscription-options',
  standalone: true,
  templateUrl: './subscription-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgForOf, SubscriptionCardComponent, NgClass, NgIf, AsyncPipe],
})
export class SubscriptionOptionsComponent implements OnInit, OnDestroy {
  @Input() theme: 'HOME' | 'SETTINGS' = 'HOME';
  @Input() freeTrialUnavailable = true;

  selectedTime = signal(TimeEnum.YEAR);
  prices$ = new BehaviorSubject<IPrice[]>([]);
  prices: IPrice[] = [];
  readonly timeEnum = TimeEnum;

  private readonly _destroy$ = new Subject<boolean>();

  constructor(private readonly _pricingService: PricingService) {}

  ngOnInit() {
    this._getPricing();
  }

  onSelectPaymentTime(time: TimeEnum) {
    this.selectedTime.set(time);
    this._prepareOptions();
  }

  private _getPricing() {
    this._pricingService
      .getPricing()
      .result$.pipe(
        filterSuccess(),
        tap(data => (this.prices = data.data.data)),
        takeUntil(this._destroy$)
      )
      .subscribe(() => this._prepareOptions());
  }

  private _prepareOptions() {
    this.prices$.next(
      this.prices.filter(price => price.mode === this.selectedTime()).sort(this.customSort)
    );
  }

  onPlanClick(priceId: string) {
    this._pricingService
      .getPaymentSessionUrl()
      .mutate(priceId)
      .then(value => {
        location.href = value.sessionUrl;
      });
  }

  customSort(a: any, b: any) {
    if (a.plan === 'Casual' && b.plan !== 'Casual') {
      return -1;
    } else if (a.plan !== 'Casual' && b.plan === 'Casual') {
      return 1;
    }

    return a.name.localeCompare(b.name);
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
