import { ChangeDetectionStrategy, Component, HostListener, signal } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { RouterLink } from '@angular/router';
import { ButtonComponent } from '../button/button.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { AuthService } from '../../../modules/auth/state/auth.service';
import { AuthRepository } from '../../../modules/auth/state/auth.repository';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [IconComponent, RouterLink, ButtonComponent, NgIf, AsyncPipe],
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  showAdditionalButtons = signal(false);
  token$ = this._authRepository.token$;

  constructor(
    private readonly _authService: AuthService,
    private _authRepository: AuthRepository
  ) {}

  logout(): void {
    this._authService.logout();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.showAdditionalButtons.set(!!scrollY);
  }
}
