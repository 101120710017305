import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonComponent } from '../../../../../../shared/components/button/button.component';
import { ModalCloseComponent } from '../../../../../../shared/components/modal/modal-close/modal-close.component';
import { CustomOverlayRef } from '../../../../../../core/services/overlay.service';
import { ModalDialogButtonsComponent } from '../../../../../../shared/components/modal/modal-dialog-buttons/modal-dialog-buttons.component';

@Component({
  selector: 'app-modal-delete-account',
  standalone: true,
  templateUrl: './modal-delete-account.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, ModalCloseComponent, ModalDialogButtonsComponent],
})
export class ModalDeleteAccountComponent {
  constructor(public dialogRef: CustomOverlayRef) {}
}
