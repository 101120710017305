import { Routes } from '@angular/router';
import { DashboardHomeLayout } from './_layout/layout';
import { GeneratePodcastPageComponent } from './pages/generate-podcast-page/generate-podcast-page.component';

export const DASHBOARD_HOME_ROUTES: Routes = [
  {
    path: '',
    component: DashboardHomeLayout,
    children: [
      { path: '', component: GeneratePodcastPageComponent },
      { path: '**', redirectTo: '' },
    ],
  },
];
