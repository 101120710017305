import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { Router, RouterLink } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';

export interface InfoData {
  title?: string;
  subtitle?: string;
  button?: string;
  buttonUrl?: string;
}

@Component({
  selector: 'app-info',
  standalone: true,
  templateUrl: './info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, RouterLink, NgIf, NgClass],
})
export class InfoComponent {
  @Input() infoData!: InfoData | null;
  @Input() emitEvent = false;
  @Output() clickButtonEmitter = new EventEmitter<void>();

  constructor(private readonly _router: Router) {}

  onButtonClick() {
    this.emitEvent
      ? this.clickButtonEmitter.emit()
      : this._router.navigate([this.infoData?.buttonUrl || '/app']);
  }
}
