import { Pipe, PipeTransform } from '@angular/core';
import { SharedService } from '../services/shared.service';

@Pipe({
  name: 'webpSupport',
  standalone: true,
})
export class WebpSupportPipe implements PipeTransform {
  constructor(private readonly _sharedService: SharedService) {}

  transform(imageUrlWithoutFormat: string, alternativeFormat: string = 'png'): string | null {
    const format: string =
      !navigator.userAgent.includes('Mac') || this._sharedService.compatibleWithWebP()
        ? 'webp'
        : alternativeFormat;
    return `${imageUrlWithoutFormat}.${format}`;
  }
}
