import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { PlayingNavComponent } from '../../components/playing-nav/playing-nav.component';
import { PlayingArticleSummaryComponent } from '../../components/playing-article-summary/playing-article-summary.component';
import { PlayingHttpService } from '../../state/playing-http.service';
import { PlayingService } from '../../state/playing.service';
import { ActivatedRoute } from '@angular/router';
import { AsyncPipe, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Observable } from 'rxjs';
import { QueryObserverResult } from '@tanstack/query-core/build/lib/types';
import { NgVarDirective } from '../../../../../../shared/directives/ng-var.directive';
import { LoaderComponent } from '../../../../../../shared/components/loader/loader.component';
import { InfoComponent } from '../../../../../../shared/components/error-info/info.component';
import { LanguagePipe } from '../../../../../../shared/pipes/language.pipe';

@Component({
  selector: 'app-podcast-playing-page',
  standalone: true,
  templateUrl: './podcast-playing-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PlayingNavComponent,
    PlayingArticleSummaryComponent,
    AsyncPipe,
    NgIf,
    NgVarDirective,
    NgSwitch,
    NgSwitchCase,
    LoaderComponent,
    InfoComponent,
    NgSwitchDefault,
  ],
  providers: [LanguagePipe, PlayingService],
})
export class PodcastPlayingPageComponent implements OnInit, OnDestroy {
  podcastId = this._activatedRoute.snapshot.paramMap.get('id');
  podcastSummaryQuery$!: Observable<QueryObserverResult>;

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _playingService: PlayingService,
    private readonly _playingHttpService: PlayingHttpService
  ) {}
  ngOnInit() {
    this._getPodcastId();
  }

  private _getPodcastId() {
    this.podcastId && (this.podcastSummaryQuery$ = this._playingService.getPodcast(this.podcastId));
  }

  ngOnDestroy() {
    this._playingHttpService.podcastSummaryQuery('').remove();
  }
}
