import { Routes } from '@angular/router';
import { LibraryLayoutComponent } from './_layout/layout';
import { PodcastLibraryComponent } from './pages/podcast-library/podcast-library.component';
import { PodcastPlayingPageComponent } from './pages/podcast-playing-page/podcast-playing-page.component';

export const LIBRARY_ROUTES: Routes = [
  {
    path: '',
    component: LibraryLayoutComponent,
    children: [
      { path: '', component: PodcastLibraryComponent },
      { path: 'play/:id', component: PodcastPlayingPageComponent },
      { path: '**', redirectTo: '' },
    ],
  },
];
