<section
  class="bg-secondary-500 py-3 text-xs text-font-primary px-[10px] md:px-5 lg:px-12 lg:py-[35px] lg:text-sm"
>
  <div class="flex justify-end mb-4 max-w-[1330px] mx-auto">
    <a href="https://www.facebook.com/" class="mr-[15px]">
      <app-icon class="text-2xl" name="facebook-icon"></app-icon>
    </a>
    <a href="https://www.instagram.com/">
      <app-icon class="text-2xl" name="instagram-icon"></app-icon>
    </a>
  </div>
  <div class="flex flex-col justify-between max-w-[1330px] mx-auto md:flex-row">
    <div class="flex flex-col gap-x-[30px] mb-5 lg:flex-row" *ngFor="let links of footerLinks">
      <a class="no-underline" *ngFor="let link of links" [routerLink]="link.url">{{ link.name }}</a>
    </div>

    <div class="text-right">
      <p>Got a question?</p>
      <p>Contact us at <a href="mailto:support@ilisten.ai">support@ilisten.ai</a></p>
      <p>© iListen.ai, 2024</p>
    </div>
  </div>
</section>
