import { Pipe, PipeTransform } from '@angular/core';

const LANGUAGE_CODE_REGEXP = /(?<=-)[^-]*/g;

@Pipe({ standalone: true, name: 'languageName' })
export class LanguagePipe implements PipeTransform {
  constructor() {}

  transform(value: any) {
    return value.match(LANGUAGE_CODE_REGEXP)[0] || value;
  }
}
