import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBaseService } from '../../../../../core/bases/form-base.service';
import { matchValues, password } from '../../../../../utils/validators';

export interface IChangePasswordForm {
  password: FormControl<string>;
  confirmPassword: FormControl<string>;
}

@Injectable({ providedIn: 'root' })
export class ChangePasswordForm extends FormBaseService<IChangePasswordForm> {
  constructor() {
    super();
    this.form = new FormGroup<IChangePasswordForm>(
      {
        password: new FormControl('', {
          nonNullable: true,
          validators: [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(256),
            password,
          ],
        }),
        confirmPassword: new FormControl('', {
          nonNullable: true,
          validators: [Validators.required],
        }),
      },
      { validators: [matchValues('password', 'confirmPassword')] }
    );
  }
}
