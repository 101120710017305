import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MutationService } from '@ngneat/query';
import { environment } from '../../../../../../environments/environment';
import { IOutput, IUser } from '../../../../auth/state/auth.types';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  constructor(private _httpService: HttpClient, private _mutationService: MutationService) {}

  updateProfile() {
    return this._mutationService.use((data: any) => {
      return this._httpService.patch<IOutput<IUser>>(`${environment.apiUrl}/users/me`, data);
    });
  }
}
