<div
  class="flex justify-center items-center min-h-full"
  *ngVar="generatePodcastFormMutation.result$ | async as data"
  [ngSwitch]="data?.status"
>
  <app-loader *ngSwitchCase="'loading'" [showSteps]="true" />
  <app-info
    [emitEvent]="true"
    [infoData]="{ subtitle: errorSubtitle || 'An error has occurred' }"
    (clickButtonEmitter)="resetFormStatus()"
    *ngSwitchCase="'error'"
  />

  <app-generate-podcast
    class="w-full"
    *ngSwitchDefault
    [form]="formVM"
    (submitFormEmitter)="onSubmitForm()"
  />
</div>
