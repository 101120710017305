import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ButtonComponent } from '../../../../../../shared/components/button/button.component';
import { ModalCloseComponent } from '../../../../../../shared/components/modal/modal-close/modal-close.component';
import { CustomOverlayRef } from '../../../../../../core/services/overlay.service';
import { InputTextComponent } from '../../../../../../shared/components/input-text/input-text.component';
import { ChangeEmailForm } from '../../forms/change-email.form';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-modal-change-email-address',
  standalone: true,
  imports: [ButtonComponent, ModalCloseComponent, InputTextComponent, ReactiveFormsModule],
  templateUrl: './modal-change-email-address.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalChangeEmailAddressComponent implements OnDestroy {
  formVM = this._changeEmailForm.vm;
  constructor(
    public dialogRef: CustomOverlayRef,
    private readonly _changeEmailForm: ChangeEmailForm
  ) {}

  changeEmail() {
    this.formVM.markAllAsTouched();

    if (!this._changeEmailForm.canSubmitForm()) return;

    this.dialogRef.close(this.formVM.value.email);
  }
  ngOnDestroy() {
    this._changeEmailForm.resetForm();
  }
}
