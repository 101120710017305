import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IPodcast } from '../../../../modules/dashboard/modules/library/state/library.types';

@Injectable({ providedIn: 'root' })
export class AudioService {
  podcast$ = new BehaviorSubject<IPodcast | null>(null);

  setAudio(podcast: IPodcast) {
    if (!podcast?.audio) return;

    podcast.audio = this.getAudioUrl(podcast.audio);

    this.podcast$.next({ ...podcast });
  }

  getAudioUrl(audio: string) {
    const binary = this.convertDataURIToBinary(audio);

    return URL.createObjectURL(new Blob([binary], { type: 'audio/aac' }));
  }

  convertDataURIToBinary(dataURI: string) {
    const BASE64_MARKER = ';base64,';
    const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    const base64 = dataURI.substring(base64Index);
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

  resetAudioSource() {
    this.podcast$.next(null);
  }
}
