import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { IUser } from './auth.types';

interface IAuthProps {
  token: string | null;
  refreshToken: string | null;
  rememberMe: boolean;
  user: IUser | null;
}

const defaultValues: IAuthProps = {
  token: null,
  refreshToken: null,
  rememberMe: true,
  user: null,
};

const authStore = createStore({ name: 'auth' }, withProps<IAuthProps>(defaultValues));

persistState(authStore, {
  key: 'auth',
  storage: localStorageStrategy,
  preStoreInit: (value: IAuthProps) => {
    return value.rememberMe ? value : defaultValues;
  },
});

@Injectable({ providedIn: 'root' })
export class AuthRepository {
  token$ = authStore.pipe(select(state => state.token));
  refreshToken$ = authStore.pipe(select(state => state.refreshToken));
  user$ = authStore.pipe(select(state => state.user));

  setToken(token: IAuthProps['token']): void {
    authStore.update(state => ({ ...state, token }));
  }

  setRefreshToken(refreshToken: IAuthProps['refreshToken']): void {
    authStore.update(state => ({ ...state, refreshToken }));
  }

  setRememberMe(rememberMe: IAuthProps['rememberMe']): void {
    authStore.update(state => ({ ...state, rememberMe }));
  }

  setUser(user: IAuthProps['user']) {
    authStore.update(state => ({ ...state, user }));
  }
}
