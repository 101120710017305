import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { getRegistry } from '@ngneat/elf';
import { MutationService, QueryClientService, QueryService } from '@ngneat/query';
import { map, Observable, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IForgotPasswordForm } from '../forms/forgot-password.form';
import { ILoginForm } from '../forms/login.form';
import { IRegisterForm } from '../forms/register.form';
import { AuthRepository } from './auth.repository';
import { IEmailStatus, IOutput, IOutputLogin, IStaticPage, IUser } from './auth.types';
import { ISetPasswordForm } from '../forms/set-password.form';
import { INTERCEPT_ERRORS } from '../../../core/interceptors/auth.interceptor';
import { HotToastService } from '@ngneat/hot-toast';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _queryClientService = inject(QueryClientService);

  constructor(
    private _httpService: HttpClient,
    private _authRepository: AuthRepository,
    private _router: Router,
    private _mutationService: MutationService,
    private _queryService: QueryService,
    private _toastService: HotToastService
  ) {}

  // NOTE used in interceptor, no need for mutation here
  refreshToken(refreshToken: string): Observable<IOutput<IOutputLogin>> {
    return this._httpService.post<IOutput<IOutputLogin>>(`${environment.apiUrl}/auth/refresh`, {
      refresh_token: refreshToken,
      mode: 'json',
    });
  }

  login() {
    return this._mutationService.use((data: FormGroup<ILoginForm>) => {
      return this._httpService
        .post<IOutput<IOutputLogin>>(`${environment.apiUrl}/auth/login`, data.value)
        .pipe(
          tap(response => {
            this._authRepository.setToken(response.data.access_token);
            this._authRepository.setRefreshToken(response.data.refresh_token);
          })
        );
    });
  }

  googleRefresh() {
    return this._httpService
      .post<IOutput<IOutputLogin>>(
        `${environment.apiUrl}/auth/refresh`,
        { mode: 'cookie' },
        { withCredentials: true }
      )
      .pipe(
        tap(response => {
          this._authRepository.setToken(response.data.access_token);
        })
      );
  }

  checkEmailStatus(email: string) {
    return this._queryService.use(['emailStatus'], () => {
      return this._httpService
        .get<{ user: IEmailStatus }>(`${environment.apiUrl}/user-confirm/email?email=${email}`)
        .pipe(
          map(response => {
            const user = response.user;

            if (!user) {
              this._toastService.warning(
                `This email address is not registered yet. Please create an account to sign in.`,
                { duration: 13000 }
              );
              return null;
            } else if (user?.status === 'invited') {
              this._toastService.warning(
                `This email address is unverified. Please use your verification link.`,
                { duration: 13000 }
              );
              return null;
            } else {
              return response;
            }
          })
        );
    });
  }

  register() {
    return this._mutationService.use((data: FormGroup<IRegisterForm>) => {
      return this._httpService.post<void>(`${environment.apiUrl}/users`, data.value);
    });
  }

  forgotPassword() {
    return this._mutationService.use((data: FormGroup<IForgotPasswordForm>) => {
      return this._httpService.post<boolean>(
        `${environment.apiUrl}/auth/password/request`,
        data.value
      );
    });
  }

  setPassword() {
    return this._mutationService.use((data: FormGroup<ISetPasswordForm>) => {
      return this._httpService.post<ISetPasswordForm>(
        `${environment.apiUrl}/auth/password/reset`,
        data.value
      );
    });
  }

  emailConfirm() {
    return this._mutationService.use((data: { token: string }) => {
      return this._httpService.post(`${environment.apiUrl}/user-confirm`, data);
    });
  }

  queryCurrentUser() {
    return this._queryService.use(
      ['current-user'],
      () =>
        this._httpService
          .get<IOutput<IUser>>(`${environment.apiUrl}/users/me`, {
            context: new HttpContext().set(INTERCEPT_ERRORS, false),
          })
          .pipe(
            tap(res => {
              if (res.data) {
                this._authRepository.setUser(res.data);
              }
            })
          ),
      {
        staleTime: Infinity,
        retryDelay: 1500,
        retry(failureCount) {
          return failureCount <= 0;
        },
      }
    );
  }

  queryStaticPage(type: string) {
    return this._queryService.use(
      ['static-page', type],
      () =>
        this._httpService.get<IOutput<Array<IStaticPage>>>(
          `${environment.apiUrl}/items/static_page`,
          {
            params: {
              filter: JSON.stringify({
                type: {
                  _eq: type,
                },
              }),
            },
          }
        ),
      { staleTime: Infinity }
    );
  }

  logout() {
    this._queryClientService.clear();
    getRegistry().forEach(store => store.reset());
    this._router.navigateByUrl('/');
  }

  handleLoginViaExtension(ext: string) {
    const authData = JSON.parse(window.atob(ext));

    if (authData['access_token'] && authData['refresh_token']) {
      this._authRepository.setToken(authData['access_token']);
      this._authRepository.setRefreshToken(authData['refresh_token']);

      this.refreshToken(authData['refresh_token']);
      this._router.navigate(['/app/library']);
    }
  }
}
