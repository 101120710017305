import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';
import { IPodcastMenuElement } from '../../state/library.types';
import { IconComponent } from '../../../../../../shared/components/icon/icon.component';
import { NgForOf } from '@angular/common';

export enum MenuActionTypeEnum {
  DELETE,
  RENAME,
  FEEDBACK,
  CLOSE,
}

const PODCAST_MENU: IPodcastMenuElement[] = [
  { icon: 'delete', name: 'Delete', actionType: MenuActionTypeEnum.DELETE },
  { icon: 'edit', name: 'Rename', actionType: MenuActionTypeEnum.RENAME },
  { icon: 'feedback', name: 'Send feedback', actionType: MenuActionTypeEnum.FEEDBACK },
];

@Component({
  selector: 'app-podcast-menu',
  standalone: true,
  templateUrl: './podcast-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconComponent, NgForOf],
})
export class PodcastMenuComponent {
  @Output() menuActionEmitter: EventEmitter<MenuActionTypeEnum> = new EventEmitter();
  podcastMenu = PODCAST_MENU;

  @HostListener('document:click')
  handleClick() {
    this.menuActionEmitter.emit(MenuActionTypeEnum.CLOSE);
  }
}
