import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from '../../../shared/components/header/header.component';
import { MenuComponent } from '../components/menu/menu.component';
import { PlayerComponent } from '../../../shared/components/player/player.component';
import { AudioService } from '../../../shared/components/player/services/audio.service';
import { NgVarDirective } from '../../../shared/directives/ng-var.directive';
import { AsyncPipe, NgClass } from '@angular/common';

@Component({
  standalone: true,
  selector: 'iw-app-dashboard-layout',
  templateUrl: './layout.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterOutlet,
    HeaderComponent,
    MenuComponent,
    PlayerComponent,
    NgVarDirective,
    AsyncPipe,
    NgClass,
  ],
})
export class DashboardLayoutComponent {
  activePodcast$ = this._audioService.podcast$;

  constructor(private readonly _audioService: AudioService) {}
}
