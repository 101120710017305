import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MutationService, QueryService } from '@ngneat/query';
import { environment } from '../../../../../../environments/environment';
import { IGeneratePodcastData, ILanguage, IVoice } from './dashboard-home.types';
import { IOutput } from '../../../../auth/state/auth.types';
import { map } from 'rxjs';
import { LanguagePipe } from '../../../../../shared/pipes/language.pipe';
import { IOption } from '../../../../../shared/components/input-select/input-select.component';
import { AudioService } from '../../../../../shared/components/player/services/audio.service';

@Injectable()
export class DashboardHomeHttpService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _mutationService: MutationService,
    private readonly _queryService: QueryService,
    private readonly _languagePipe: LanguagePipe,
    private readonly _audioService: AudioService
  ) {}

  generatePodcast() {
    return this._mutationService.use(
      (data: IGeneratePodcastData) => {
        return this._httpClient.post<any>(`${environment.apiUrl}/items/library`, data);
      },
      { retry: false }
    );
  }

  getVoices(languageCode: string) {
    return this._queryService.use(
      ['voicesList'],
      () => {
        return this._httpClient
          .get<IOutput<IVoice[]>>(
            `${environment.apiUrl}/items/voices?fields=id,displayName,audio&filter[languageCode][_contains]=${languageCode}`
          )
          .pipe(
            map(value =>
              value?.data?.map(data => {
                return {
                  key: data.displayName,
                  value: data.id,
                  audio: this._prepareVoiceAudio(data.audio),
                };
              })
            )
          );
      },
      {
        refetchOnWindowFocus: false,
      }
    );
  }

  getLanguages() {
    return this._queryService.use(
      ['languagesList'],
      () => {
        return this._httpClient
          .get<IOutput<ILanguage[]>>(
            `${environment.apiUrl}/items/voices?fields=languageCode,languageIco,name`
          )
          .pipe(
            map(value =>
              value?.data?.map(data => {
                return {
                  key: this._languagePipe.transform(data.languageCode),
                  value: data.languageCode,
                  icon: environment.apiUrl + '/assets/' + data.languageIco,
                };
              })
            ),
            map(data => this._filterUnusedLanguages(data))
          );
      },
      {
        refetchOnWindowFocus: false,
      }
    );
  }

  private _filterUnusedLanguages(data: IOption[]) {
    let uniqueLanguageCodes: string[] = [];

    return data.filter(item => {
      if (!uniqueLanguageCodes.includes(item.key)) {
        uniqueLanguageCodes.push(item.key);
        return item;
      } else {
        return;
      }
    });
  }

  private _prepareVoiceAudio(audio?: string) {
    return audio ? this._audioService.getAudioUrl('data:audio/aac;base64, ' + audio) : '';
  }
}
