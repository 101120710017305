<section
  class="px-[3%] py-[10%] md:p-[100px] bg-secondary-500 rounded-3xl md:rounded-[50px] lg:w-[60vw] max-w-[820px] text-center mx-auto"
  *ngIf="{
    voicesQuery: voicesQuery$ | async,
    languagesQuery: languagesQuery$ | async,
    userActivePlan: userActivePlan$ | async,
  } as vm"
>
  <a
    href="https://chromewebstore.google.com/detail/ilisten-ai/ljhdeapjblcmgccanjhoddbkcoijlgpk"
    target="_blank"
    class="flex items-center justify-center gap-3 py-2 mx-2 rounded-[30px] no-underline bg-gray-200 border-[1px] border-black"
  >
    <app-icon class="text-[20px]" name="chrome-logo" /> <span>Get Chrome Extension</span>
  </a>

  <p class="my-8 text-xl">Or drop in a URL</p>

  <form [formGroup]="form" (ngSubmit)="submitFormEmitter.emit()">
    <app-input-text
      class="text-left"
      formControlName="url"
      [showLeftIcon]="true"
      [dashboardInput]="true"
      placeholder="Drop in a URL here"
    ></app-input-text>
    <div class="my-8 flex items-center justify-between gap-2 md:gap-5">
      <app-input-select
        id="languageSelect"
        formControlName="language"
        [selectData]="vm?.languagesQuery?.data"
        [queryStatus]="vm?.languagesQuery?.status!"
        class="flex-grow"
      />
      <app-input-select
        id="voiceSelect"
        [selectData]="vm.voicesQuery?.data"
        [queryStatus]="vm.voicesQuery?.status! || vm.languagesQuery?.status!"
        formControlName="voice"
        class="flex-grow"
      />
      <app-input-select
        id="speedSelect"
        [theme]="selectThemeEnum.SPEED"
        [selectData]="playbackSpeed"
        [initialValue]="playbackSpeed[2]"
        formControlName="playback_speed"
        class="flex-grow"
      />
    </div>

    <div class="text-left">
      <p class="pb-7 text-sm">Select audio length</p>
      <div class="px-2.5 md:px-0 min-h-[48px] flex justify-center">
        <app-slider
          *ngIf="vm.userActivePlan?.status !== 'loading'"
          class="w-full"
          formControlName="summary_length"
          [step]="1"
          [minCount]="1"
          [maxCountSetter]="vm?.userActivePlan?.data?.[0]?.name === 'Casual' ? 2 : 3 || 2"
        ></app-slider>
        <app-loader
          class="max-w-[80px] h-[32px] flex items-center"
          *ngIf="vm.userActivePlan?.status === 'loading'"
        ></app-loader>
      </div>
    </div>

    <app-button class="mx-auto mt-8" type="submit"> Generate podcast </app-button>
  </form>
</section>
