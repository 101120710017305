import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBaseService } from '../../../../../core/bases/form-base.service';
import { IOption } from '../../../../../shared/components/input-select/input-select.component';

export interface IGeneratePodcastForm {
  url: FormControl<string>;
  language: FormControl<IOption | null>;
  voice: FormControl<IOption | null>;
  playback_speed: FormControl<number>;
  summary_length: FormControl<number>;
}

@Injectable({ providedIn: 'root' })
export class GeneratePodcastForm extends FormBaseService<IGeneratePodcastForm> {
  constructor() {
    super();
    this.form = new FormGroup<IGeneratePodcastForm>({
      url: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      language: new FormControl(null, {
        validators: [Validators.required],
      }),
      voice: new FormControl(null, {
        validators: [Validators.required],
      }),
      playback_speed: new FormControl(1, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      summary_length: new FormControl(2, {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }
}
