<section class="bg-white rounded-[4px] px-[45px] py-[40px] w-screen max-w-[490px] relative">
  <app-modal-close (click)="dialogRef.close()"></app-modal-close>
  <h3 class="text-center max-w-[230px] mx-auto mb-8 text-xl">Change password</h3>
  <form [formGroup]="formVM" (ngSubmit)="changePassword()">
    <app-input-text
      class="mb-4"
      id="password"
      formControlName="password"
      label="New password"
      type="password"
    ></app-input-text>

    <app-input-text
      id="confirmPassword"
      formControlName="confirmPassword"
      label="Confirm new password"
      type="password"
    ></app-input-text>

    <app-button class="mt-[120px]" type="submit" [fullWidth]="true">Change password</app-button>
  </form>
</section>
