<div
  class="menu shadow-primary flex justify-center items-center rounded-lg bg-white lg:hidden"
  [ngClass]="{ 'open !bg-secondary-500 shadow-none': showMenu() }"
  (click)="onMenuClick()"
>
  <div class="icon"></div>
</div>
<section
  class="fixed shadow-primary duration-500 -left-full lg:static z-50 min-w-[250px] bg-secondary-500 h-[120vh] lg:h-full"
  [ngClass]="{ '!left-0': showMenu() }"
  style="-webkit-transform: translate3d(0, 0, 0)"
>
  <div class="fixed top-5 lg:top-0 py-10">
    <img class="w-[150px] ml-6 mb-11" src="assets/icons/ilisten-logo.svg" alt="iListen logo" />

    <div>
      <a
        class="flex items-center gap-4 py-[10px] no-underline border-primary-500 pl-6"
        routerLinkActive="border-l-4 !pl-5"
        [routerLinkActiveOptions]="{ exact: item.name === 'Home' }"
        [routerLink]="'/app' + item.url"
        (click)="showMenu.set(false)"
        *ngFor="let item of menuItems"
      >
        <app-icon class="text-lg" [name]="item.icon"></app-icon>
        <span>{{ item.name }}</span>
      </a>
      <div
        class="cursor-pointer flex items-center gap-4 pl-6 py-[10px] no-underline"
        (click)="logout()"
      >
        <app-icon class="text-lg" name="logout"></app-icon>
        <span>Logout</span>
      </div>
    </div>
  </div>
</section>
