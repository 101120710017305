import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { of, switchMap, take } from 'rxjs';
import { AuthRepository } from '../../modules/auth/state/auth.repository';

export function authGuard({
  redirectTo,
  isProtected = true,
}: {
  redirectTo: string[];
  isProtected?: boolean;
}): CanMatchFn {
  return () => {
    const authRepository = inject(AuthRepository);
    const router = inject(Router);

    return authRepository.token$.pipe(
      take(1),
      switchMap(res => {
        const token = res;

        if (isProtected) {
          if (token) {
            return of(true);
          }
        } else {
          if (!token) {
            return of(true);
          }
        }

        return of(router.createUrlTree(redirectTo ?? ['/']));
      })
    );
  };
}
