import {
  AsyncPipe,
  NgClass,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgTemplateOutlet,
} from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  Optional,
  signal,
  WritableSignal,
} from '@angular/core';
import { FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { InputTextComponent } from '../input-text/input-text.component';
import { InputBaseComponent } from '../../../core/bases/input-base.component';
import { IconComponent } from '../icon/icon.component';
import { InputSelectService } from './input-select.service';
import { LoaderComponent } from '../loader/loader.component';
import { NgVarDirective } from '../../directives/ng-var.directive';

export interface IOption {
  value: string | number;
  key: string;
  icon?: string;
  audio?: string;
}

export enum SelectThemeEnum {
  REGULAR,
  SPEED,
}

@Component({
  selector: 'app-input-select',
  standalone: true,
  templateUrl: './input-select.component.html',
  imports: [
    AsyncPipe,
    FormsModule,
    InputTextComponent,
    NgClass,
    NgFor,
    NgIf,
    ReactiveFormsModule,
    IconComponent,
    NgTemplateOutlet,
    LoaderComponent,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    NgVarDirective,
  ],
})
export class InputSelectComponent extends InputBaseComponent<any> {
  @Input() id!: string;
  @Input() initialValue: any;
  @Input() rightIcon!: string;
  @Input() queryStatus!: 'success' | 'error' | 'loading';
  @Input() theme = SelectThemeEnum.REGULAR;

  @Input() set selectData(options: IOption[] | any) {
    this.options = options;

    setTimeout(() => {
      this._setInitialValue();
    });
  }

  activeSampleId: WritableSignal<string | null> = signal(null);
  options!: IOption[] | any;
  selectedDropdown$ = this._inputSelectService.selectedDropdown$;
  readonly selectThemeEnum = SelectThemeEnum;

  constructor(
    @Optional() ngControl: NgControl,
    private readonly _inputSelectService: InputSelectService,
    private readonly _cdr: ChangeDetectorRef
  ) {
    super(ngControl);
  }

  private _setInitialValue() {
    this.options?.length && this.ngControl.control?.setValue(this.initialValue || this.options[0]);
    this._cdr.markForCheck();
  }

  onShowItemsList() {
    this.playAudio(false);
    if (!this.options?.length) return;

    setTimeout(() => {
      this.selectedDropdown$.value === this.id
        ? this.selectedDropdown$.next(this.selectedDropdown$.value ? '' : this.id)
        : this.selectedDropdown$.next(this.id);
    });
  }

  onSelectItem(selectedOption: IOption) {
    this.ngControl.control?.setValue(selectedOption);
    this.playAudio(false);
    this.selectedDropdown$.next('');
  }

  playAudio(startAudio: boolean, audio?: string, sampleId?: string) {
    this._inputSelectService.voiceSample?.pause();
    this._inputSelectService.voiceSample = null;
    this.activeSampleId.set(null);

    if (startAudio && audio && sampleId) {
      this._inputSelectService.voiceSample = new Audio(audio);
      this._inputSelectService.voiceSample?.play();
      this.activeSampleId.set(sampleId);
    }
  }

  @HostListener('document:click')
  handleClick() {
    if (this.selectedDropdown$.value) {
      this.playAudio(false);
      setTimeout(() => {
        this.selectedDropdown$.next('');
      });
    }
  }
}
