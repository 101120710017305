<header class="py-[15px] px-2 md:px-5 lg:px-12 lg:py-[40px] fixed bg-white min-w-full z-50">
  <div class="flex justify-between max-w-[1254px] mx-auto">
    <img
      routerLink="/"
      class="w-[120px] lg:w-[150px] cursor-pointer"
      src="assets/icons/ilisten-logo.svg"
      alt="iListen logo"
    />

    <ul
      class="flex md:h-[40px] items-center gap-5 text-[14px] text-[#303031] whitespace-nowrap"
      *ngIf="!(token$ | async); else loggedUserMenu"
    >
      <li>
        <a routerLink="/pricing" class="no-underline">Pricing</a>
      </li>
      <li><a routerLink="/auth" class="no-underline font-bold">Log in</a></li>

      <ng-container *ngIf="showAdditionalButtons()">
        <li class="hidden md:flex w-[160px] ml-[10px]">
          <a
            routerLink="/auth/register"
            class="inline-block py-[10px] px-[15px] bg-primary-500 text-[#fff] rounded-[30px] w-full text-center font-semibold no-underline"
            >Try for free</a
          >
        </li>
        <li class="hidden md:flex">
          <a
            href="https://chromewebstore.google.com/detail/ilisten-ai/ljhdeapjblcmgccanjhoddbkcoijlgpk"
            target="_blank"
            class="flex gap-[10px] no-underline rounded-[30px] border py-[9px] px-[15px] font-semibold"
            ><app-icon class="text-[20px]" name="chrome-logo" /> <span>Add to Chrome</span></a
          >
        </li>
      </ng-container>
    </ul>
  </div>
</header>

<ng-template #loggedUserMenu>
  <ul
    class="hidden md:flex md:h-[40px] items-center gap-5 text-[14px] text-[#303031] whitespace-nowrap"
  >
    <li><a routerLink="/pricing" class="no-underline">Pricing</a></li>
    <li><a (click)="logout()" class="no-underline font-bold mr-[10px]">Log out</a></li>
    <ng-container *ngIf="showAdditionalButtons()">
      <li class="hidden md:flex">
        <a
          href="https://chromewebstore.google.com/detail/ilisten-ai/ljhdeapjblcmgccanjhoddbkcoijlgpk"
          target="_blank"
          class="flex gap-[10px] no-underline rounded-[30px] border py-[9px] px-[15px] font-semibold"
          ><app-icon class="text-[20px]" name="chrome-logo" /> <span>Add to Chrome</span></a
        >
      </li>
    </ng-container>
  </ul>
</ng-template>
