<section class="border-b-2 border-secondary-500 mb-[40px] h-[40px] max-w-[900px]">
  <a
    class="inline-block mr-3 md:mr-[30px] pb-[14px] px-[15px] no-underline border-primary-500"
    routerLinkActive="border-b-2 text-primary-500"
    [routerLinkActiveOptions]="{ exact: true }"
    [routerLink]="'/app/settings' + link.url"
    *ngFor="let link of links"
    >{{ link.name }}
  </a>
</section>
