import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { LoaderComponent } from '../../../../../../shared/components/loader/loader.component';
import { GeneratePodcastComponent } from '../../components/generate-podcast/generate-podcast.component';
import { GeneratePodcastForm } from '../../forms/generate-podcast.form';
import { DashboardHomeHttpService } from '../../state/dashboard-home-http.service';
import { AsyncPipe, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { ButtonComponent } from '../../../../../../shared/components/button/button.component';
import { Router, RouterLink } from '@angular/router';
import { InfoComponent } from '../../../../../../shared/components/error-info/info.component';
import { DashboardHomeService } from '../../state/dashboard-home.service';
import { LanguagePipe } from '../../../../../../shared/pipes/language.pipe';
import { AudioService } from '../../../../../../shared/components/player/services/audio.service';
import { NgVarDirective } from '../../../../../../shared/directives/ng-var.directive';

@Component({
  selector: 'app-generate-podcast-page',
  standalone: true,
  templateUrl: './generate-podcast-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LoaderComponent,
    GeneratePodcastComponent,
    NgIf,
    AsyncPipe,
    ButtonComponent,
    NgSwitchCase,
    RouterLink,
    NgSwitch,
    NgSwitchDefault,
    InfoComponent,
    NgVarDirective,
  ],
  providers: [LanguagePipe, DashboardHomeHttpService, DashboardHomeService],
})
export class GeneratePodcastPageComponent implements OnDestroy {
  errorSubtitle = '';
  formVM = this._generatePodcastForm.vm;
  generatePodcastFormMutation = this._dashboardHomeHttpService.generatePodcast();

  constructor(
    private readonly _generatePodcastForm: GeneratePodcastForm,
    private _dashboardHomeHttpService: DashboardHomeHttpService,
    private _dashboardHomeService: DashboardHomeService,
    private readonly _router: Router,
    private readonly _audioService: AudioService
  ) {}

  onSubmitForm() {
    if (!this._generatePodcastForm.canSubmitForm()) return this.formVM.markAllAsTouched();

    this._audioService.resetAudioSource();

    this.generatePodcastFormMutation
      .mutate(this._dashboardHomeService.prepareDataToGeneratePodcast(this.formVM.getRawValue()))
      .then(value => {
        this._router.navigate([`/app/library/play/${value?.data?.id}`], {
          queryParams: { showStatus: true },
        });
        this._generatePodcastForm.resetForm();
      })
      .catch(error => {
        error.status === 403 && this._router.navigate(['/app/inactive-subscription']);
      });
  }

  resetFormStatus() {
    this._generatePodcastForm.resetForm();
    this.generatePodcastFormMutation.reset();
  }

  ngOnDestroy() {
    this.resetFormStatus();
  }
}
