import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from '../../../../../../shared/components/button/button.component';
import { IconComponent } from '../../../../../../shared/components/icon/icon.component';
import { InputTextComponent } from '../../../../../../shared/components/input-text/input-text.component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  InputSelectComponent,
  SelectThemeEnum,
} from '../../../../../../shared/components/input-select/input-select.component';
import { SliderComponent } from '../../../../../../shared/components/slider/slider.component';
import { ILanguage } from '../../state/dashboard-home.types';
import { AsyncPipe, NgIf } from '@angular/common';
import { DashboardHomeService } from '../../state/dashboard-home.service';
import { NgVarDirective } from '../../../../../../shared/directives/ng-var.directive';
import { LoaderComponent } from '../../../../../../shared/components/loader/loader.component';
import { PricingService } from '../../../settings/state/pricing.service';

const PLAYBACK_SPEED = [
  { key: '', value: 0.5 },
  { key: '', value: 0.7 },
  { key: '', value: 1 },
  { key: '', value: 1.2 },
  { key: '', value: 1.5 },
];

@Component({
  selector: 'app-generate-podcast',
  standalone: true,
  templateUrl: './generate-podcast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ButtonComponent,
    IconComponent,
    InputTextComponent,
    ReactiveFormsModule,
    InputSelectComponent,
    SliderComponent,
    AsyncPipe,
    NgVarDirective,
    NgIf,
    LoaderComponent,
  ],
})
export class GeneratePodcastComponent {
  @Input() form!: FormGroup;
  @Input() languages!: ILanguage[];
  @Output() submitFormEmitter = new EventEmitter<void>();

  readonly selectThemeEnum = SelectThemeEnum;
  playbackSpeed = PLAYBACK_SPEED;
  languagesQuery$ = this._dashboardHomeService.getLanguages();
  voicesQuery$ = this._dashboardHomeService.languageSelectListener();
  userActivePlan$ = this._pricingService.getActiveUserPlan().result$;

  constructor(
    private readonly _dashboardHomeService: DashboardHomeService,
    private readonly _pricingService: PricingService
  ) {}
}
