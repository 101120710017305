import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import {
  AnimationLoader,
  AnimationOptions,
  LottieComponent,
  LottieModule,
  provideLottieOptions,
} from 'ngx-lottie';
import { NgIf } from '@angular/common';

const ANIMATION_OPTIONS: AnimationOptions = {
  path: '/assets/animations/wave.json',
};

const STEPS: string[] = ['Reading', 'Thinking', 'Generating'];

@Component({
  selector: 'app-loader',
  standalone: true,
  templateUrl: './loader.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LottieComponent, LottieModule, NgIf],
  providers: [
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    AnimationLoader,
  ],
})
export class LoaderComponent implements OnInit, OnDestroy {
  @Input() showSteps = false;

  options = ANIMATION_OPTIONS;
  stepsInterval: any;
  steps = STEPS;
  stepId = signal(0);
  isComplete = signal(false);

  ngOnInit() {
    this.showSteps && this._runStepsInterval();
  }

  private _runStepsInterval() {
    this.stepsInterval = setInterval(() => {
      this.stepId() < 2 ? this.stepId.set(this.stepId() + 1) : this.removeInterval();
    }, 20000);
  }

  removeInterval() {
    clearInterval(this.stepsInterval);
  }

  ngOnDestroy() {
    this.removeInterval();
  }

  protected readonly signal = signal;
}
