import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonComponent } from '../../../../../../shared/components/button/button.component';
import { ModalCloseComponent } from '../../../../../../shared/components/modal/modal-close/modal-close.component';
import { CustomOverlayRef } from '../../../../../../core/services/overlay.service';

@Component({
  selector: 'app-modal-feedback',
  standalone: true,
  templateUrl: './modal-feedback.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, ModalCloseComponent],
})
export class ModalFeedbackComponent {
  constructor(public dialogRef: CustomOverlayRef) {}
}
