import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function getErrorMessage(errors: ValidationErrors) {
  const key = Object.keys(errors)[0];

  const messages: { [key: string]: string } = {
    required: 'Required field',
    requiredTrue: 'Required field',
    email: 'Invalid email',
    minlength: `Minimum ${errors[key]?.requiredLength} characters`,
    maxlength: `Maximum ${errors[key]?.requiredLength} characters`,
    min: `Minimum - ${errors[key]?.min}`,
    max: `Maximum - ${errors[key]?.max}`,
    matchPassword: "The passwords don't match",
    matchEmail: "The emails don't match",
  };

  return messages[key];
}

export function email(AC: AbstractControl) {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return AC.value && !regex.test(AC.value) ? { email: true } : null;
}

export function password(AC: AbstractControl) {
  const hasUpper = /[A-Z]/.test(AC.value);
  const hasLower = /[a-z]/.test(AC.value);
  const hasSpecialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(AC.value);

  if (AC.value) {
    if (!hasUpper || !hasLower || !hasSpecialCharacter) {
      return { password: true };
    }
  }

  return null;
}

export function matchValues(
  firstValue: string,
  secondValue: string,
  type: 'password' | 'email' = 'password'
): ValidatorFn | any {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[firstValue];
    const matchingControl = formGroup.controls[secondValue];

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors(
        type === 'password' ? { matchPassword: true } : { matchEmail: true }
      );
    } else {
      matchingControl.setErrors(null);
    }
  };
}
