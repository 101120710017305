import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { of } from 'rxjs';

export function EmailConfirmGuard(): CanActivateFn {
  return route => {
    const router = inject(Router);
    const token = route.queryParams['token'];

    return of(
      token
        ? router.createUrlTree(['/auth/email-confirm/status'], {
            queryParams: {
              token,
            },
          })
        : router.createUrlTree(['/'])
    );
  };
}
