import { Routes } from '@angular/router';
import { BlogLayoutComponent } from './_layout/layout';

export const BLOG_ROUTES: Routes = [
  {
    path: '',
    component: BlogLayoutComponent,
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./pages/blog-list-page/blog-list-page.component').then(
            x => x.BlogListPageComponent
          ),
      },
      {
        path: ':slug',
        loadComponent: () =>
          import('./pages/article-page/article-page.component').then(x => x.ArticlePageComponent),
      },
      { path: '**', redirectTo: '' },
    ],
  },
];
