import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgForOf } from '@angular/common';
import { RouterLink } from '@angular/router';
import { IconComponent } from '../icon/icon.component';

export interface Link {
  name: string;
  url: string;
}

const FOOTER_LINKS: Link[][] = [
  [
    {
      name: 'Terms & Conditions',
      url: '/terms-of-service',
    },
    {
      name: 'Privacy Policy',
      url: '/privacy-policy',
    },
  ],
  [
    {
      name: 'Pricing',
      url: '/pricing',
    },
    {
      name: 'Blog',
      url: '/blog',
    },
  ],
];

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [NgForOf, RouterLink, IconComponent],
  templateUrl: './footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  footerLinks = FOOTER_LINKS;
}
