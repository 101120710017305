<ng-container *ngVar="podcast$ | async as podcast">
  <section
    class="bg-secondary-500 w-full flex flex-col pm:flex-row items-center justify-center py-[20px] border-t-[1px] border-black lg:pl-[250px] px-2 pm:px-0 select-none"
    *ngIf="podcast?.audio"
    [formGroup]="formVM"
  >
    <a
      class="flex items-center flex-nowrap w-full mb-3 pm:mb-0 pm:mr-[50px] pm:max-w-[210px] pm:pl-5 lg:pl-0 overflow-hidden cursor-pointer no-underline"
      [routerLink]="['/app/library/play', podcast?.id]"
    >
      <div class="min-w-[34px] min-h-[34px] mr-3 relative">
        <div class="w-full h-full flex justify-center items-center absolute">
          <img class="max-h-full max-w-full" podcastIcon [podcastUrl]="podcast.url" />
        </div>
      </div>
      <div class="min-w-full pm:min-w-[80%] truncate text-sm relative">
        <div class="w-full overflow-hidden pl-[100%] box-content">
          <p class="title">
            {{ podcast.title }}
          </p>
        </div>
        <p class="text-xs">
          {{ podcast?.author || '' }}
          {{ podcast?.date_created | date : 'dd.MM.yyyy' }}
        </p>
      </div>
    </a>

    <div class="w-full pm:w-[550px] flex flex-col items-center mb-3 pm:mb-0">
      <div class="flex gap-5 items-center">
        <app-icon
          (click)="onShuffle()"
          class="text-sm cursor-pointer p-1.5 rounded-2xl"
          [ngClass]="{ 'bg-gray-500': shuffleActive() }"
          name="shuffle"
        ></app-icon>
        <app-icon
          class="text-sm cursor-pointer"
          name="skip"
          (click)="newPodcastAction$.next(getPodcastModeEnum.PREVIOUS)"
        ></app-icon>
        <app-icon
          class="text-4xl cursor-pointer px-3"
          name="circle-play"
          (click)="play(true)"
          *ngIf="player.paused"
        ></app-icon>
        <app-icon
          class="text-4xl cursor-pointer px-3"
          name="circle-pause"
          (click)="play(false)"
          *ngIf="!player.paused"
        ></app-icon>
        <app-icon
          class="text-sm cursor-pointer rotate-180"
          name="skip"
          (click)="newPodcastAction$.next(getPodcastModeEnum.NEXT)"
        ></app-icon>
        <app-icon
          class="text-sm cursor-pointer p-1.5 rounded-2xl"
          [ngClass]="{ 'bg-gray-500': loopPlaybackActive() }"
          name="rotate-right"
          (click)="onLoopPlayback()"
        ></app-icon>
      </div>
      <div class="min-w-full flex items-center gap-3">
        <span class="inline-block w-[43px]">{{
          formVM.controls.audio.value || 0 | secondsToMinutes
        }}</span>
        <app-slider
          [minCount]="0"
          [maxCountSetter]="maxCount"
          class="flex-grow"
          formControlName="audio"
          mode="player"
          (manualChangeEmitter)="onChangeCurrentTime($event)"
          (mouseDownEmitter)="timeChangeActive.set(true)"
          (mouseUpEmitter)="timeChangeActive.set(false)"
        ></app-slider>
        <span class="inline-block w-[43px]">{{ audioLength | secondsToMinutes }}</span>
      </div>
    </div>

    <div
      class="w-full flex justify-between pm:justify-start gap-4 self-end pm:max-w-[210px] lg:max-w-[230px] pm:ml-5"
    >
      <div class="playback-speed relative flex justify-center">
        <div
          class="text-center inline-block ml-auto cursor-pointer w-[26px]"
          (click)="$event.stopPropagation(); onPlaybackSpeedClick()"
        >
          <img src="assets/svg/speed-player.svg" class="w-full" />
          <span class="block -mt-[2px] text-sm">{{ player.playbackRate }}x</span>
          <img src="assets/svg/speed-player.svg" class="w-full" />
        </div>

        <div
          class="absolute flex left-0 pm:left-auto -top-10 bg-white z-50 rounded-xl overflow-hidden shadow-primary"
          *ngIf="showPlaybackSpeedMenu()"
        >
          <div
            *ngFor="let speed of playbackSpeed"
            class="text-center inline-block ml-auto cursor-pointer py-2 w-[35px] hover:bg-gray-500"
            [ngClass]="{ 'bg-gray-500': player.playbackRate === speed }"
            (click)="$event.stopPropagation(); onPlaybackSpeedChange(speed)"
          >
            <span class="block -mt-[2px] text-sm">{{ speed }}</span>
          </div>
        </div>
      </div>

      <div *ngIf="!iOSUserAgent" class="flex items-center gap-3">
        <app-icon class="text-md mt-1" name="volume"></app-icon>
        <app-slider
          class="w-[100px]"
          formControlName="volume"
          [maxCountSetter]="1"
          [minCount]="0"
          [step]="0.1"
          mode="player"
        ></app-slider>
      </div>
    </div>

    <audio
      (loadeddata)="onLoad()"
      (timeupdate)="onTimeUpdate()"
      (ended)="onAudioEnded()"
      [src]="podcast.audio | safe : 'url'"
      class="hidden"
      controls
      #player
    ></audio>
  </section>
</ng-container>
