import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InputTextComponent } from '../../../../../../shared/components/input-text/input-text.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-library-search-bar',
  standalone: true,
  templateUrl: './library-search-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [InputTextComponent, ReactiveFormsModule],
})
export class LibrarySearchBarComponent {
  @Input() searchControl!: FormControl;
}
