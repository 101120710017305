import { Injectable, signal } from '@angular/core';
import { OverlayService } from '../../core/services/overlay.service';
import { ModalLoaderComponent } from '../components/modal/modal-loader/modal-loader.component';

@Injectable({ providedIn: 'root' })
export class SharedService {
  compatibleWithWebP = signal(false);

  constructor(private readonly _overlayService: OverlayService) {
    this._checkBrowserCompatibilityWithWebP();
  }

  private _checkBrowserCompatibilityWithWebP(): void {
    const canvasElement: HTMLCanvasElement = document.createElement('canvas');
    if (!canvasElement.getContext?.('2d')) {
      this.compatibleWithWebP.set(false);
      return;
    }
    this.compatibleWithWebP.set(
      canvasElement.toDataURL('image/webp').indexOf('data:image/webp') === 0
    );
  }

  showLoader() {
    this._overlayService.open(
      { position: 'cc', disableBackdropClickClose: true },
      ModalLoaderComponent
    );
  }

  hideLoader() {
    this._overlayService.close();
  }
}
