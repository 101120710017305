import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ButtonComponent,
  ButtonStyleClassEnum,
} from '../../../../../../shared/components/button/button.component';
import { NgForOf } from '@angular/common';
import { FormControl } from '@angular/forms';
import { ISortButton } from '../../state/library.types';

export enum FilterTypeEnum {
  ALL,
  STARTED,
  FINISHED,
}

const FILTER_BUTTONS: ISortButton[] = [
  {
    name: 'All',
    value: FilterTypeEnum.ALL,
  },
  {
    name: 'Started',
    value: FilterTypeEnum.STARTED,
  },
  {
    name: 'Finished',
    value: FilterTypeEnum.FINISHED,
  },
];

@Component({
  selector: 'app-library-filter-buttons',
  standalone: true,
  templateUrl: './library-filter-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, NgForOf],
})
export class LibraryFilterButtonsComponent {
  @Input() filterControl!: FormControl;
  filterButtons = FILTER_BUTTONS;
  readonly ButtonStyleClassEnum = ButtonStyleClassEnum;

  changeSortType(sortType: FilterTypeEnum) {
    this.filterControl.setValue(sortType);
  }
}
