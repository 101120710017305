import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum DeviceType {
  MOBILE,
  TABLET,
  DESKTOP,
}

@Injectable({
  providedIn: 'root',
})
export class ResolutionService {
  private deviceType$ = new BehaviorSubject<DeviceType>(this.getInitialDeviceType());

  constructor() {
    this._initWindowResizeListener();
  }

  private _initWindowResizeListener() {
    window.addEventListener('resize', event => this._updateDeviceType());
  }

  getDeviceType(): Observable<DeviceType> {
    return this.deviceType$.asObservable();
  }

  private getInitialDeviceType(): DeviceType {
    const width = window.innerWidth;
    if (width < 768) {
      return DeviceType.MOBILE;
    } else if (width >= 768 && width <= 1023) {
      return DeviceType.TABLET;
    } else {
      return DeviceType.DESKTOP;
    }
  }

  private _updateDeviceType(): void {
    const width = window.innerWidth;
    let deviceType: DeviceType;

    if (width < 768) {
      deviceType = DeviceType.MOBILE;
    } else if (width >= 768 && width <= 1023) {
      deviceType = DeviceType.TABLET;
    } else {
      deviceType = DeviceType.DESKTOP;
    }

    this.deviceType$.next(deviceType);
  }
}
