import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBaseService } from '../../../../core/bases/form-base.service';

export interface IAudioPlayerForm {
  audio: FormControl<number>;
  volume: FormControl<number>;
}

@Injectable({ providedIn: 'root' })
export class AudioPlayerForm extends FormBaseService<IAudioPlayerForm> {
  constructor() {
    super();
    this.form = new FormGroup<IAudioPlayerForm>({
      audio: new FormControl(0, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      volume: new FormControl(1, {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }
}
