import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SettingsNavComponent } from '../components/settings-nav/settings-nav.component';
import { HideElementDirective } from '../../../../../shared/directives/hide-element.directive';
import { PageTitleService } from '../../../../../shared/services/page-title.service';

@Component({
  standalone: true,
  selector: 'iw-app-settings-layout',
  templateUrl: './layout.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, SettingsNavComponent, HideElementDirective],
})
export class SettingsLayoutComponent implements OnInit {
  constructor(private readonly _pageTitleService: PageTitleService) {}

  ngOnInit() {
    this._pageTitleService.setTitle('Settings - iListen');
  }
}
