import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ButtonComponent } from '../../../../../../shared/components/button/button.component';
import { InputTextComponent } from '../../../../../../shared/components/input-text/input-text.component';
import { ModalCloseComponent } from '../../../../../../shared/components/modal/modal-close/modal-close.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomOverlayRef } from '../../../../../../core/services/overlay.service';
import { ChangePasswordForm } from '../../forms/change-password.form';

@Component({
  selector: 'app-modal-change-password',
  standalone: true,
  imports: [ButtonComponent, InputTextComponent, ModalCloseComponent, ReactiveFormsModule],
  templateUrl: './modal-change-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalChangePasswordComponent implements OnDestroy {
  formVM = this._changePasswordForm.vm;

  constructor(
    public dialogRef: CustomOverlayRef,
    private readonly _changePasswordForm: ChangePasswordForm
  ) {}

  changePassword() {
    this.formVM.markAllAsTouched();

    if (!this._changePasswordForm.canSubmitForm()) return;

    this.dialogRef.close(this.formVM.value.password);
  }

  ngOnDestroy() {
    this._changePasswordForm.resetForm();
  }
}
