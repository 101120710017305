import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ButtonComponent } from '../../../../../../shared/components/button/button.component';
import { InputTextComponent } from '../../../../../../shared/components/input-text/input-text.component';
import { ModalCloseComponent } from '../../../../../../shared/components/modal/modal-close/modal-close.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomOverlayRef } from '../../../../../../core/services/overlay.service';
import { RenamePodcastForm } from '../../forms/rename-podcast.form';
import { ModalDialogButtonsComponent } from '../../../../../../shared/components/modal/modal-dialog-buttons/modal-dialog-buttons.component';

@Component({
  selector: 'app-modal-podcast-rename',
  standalone: true,
  templateUrl: './modal-podcast-rename.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ButtonComponent,
    InputTextComponent,
    ModalCloseComponent,
    ReactiveFormsModule,
    ModalDialogButtonsComponent,
  ],
})
export class ModalPodcastRenameComponent implements OnDestroy {
  formVM = this._renamePodcastForm.vm;
  constructor(
    public readonly dialogRef: CustomOverlayRef,
    private readonly _renamePodcastForm: RenamePodcastForm
  ) {}

  changePodcastName() {
    this.formVM.markAllAsTouched();

    if (!this._renamePodcastForm.canSubmitForm()) return;

    this.dialogRef.close(this.formVM.getRawValue());
  }
  ngOnDestroy() {
    this._renamePodcastForm.resetForm();
  }
}
