<section class="border-secondary-500 border overflow-hidden rounded-[20px] text-sm min-h-[120px]">
  <div class="pl-2 md:pl-8 pr-7 md:pr-14 py-3 border-secondary-500 border-b">
    <span class="inline-block w-[45%] md:w-[40%]">Title</span>
    <span class="inline-block w-[30%] md:w-[25%]">Date added</span>
    <span class="inline-block">Summary</span>
  </div>
  <div class="overflow-y-auto h-[calc(100vh-500px)] min-h-[384px]">
    <ng-container [ngTemplateOutlet]="loading ? loader : podcasts"></ng-container>
  </div>
</section>
<div class="text-center">
  <app-pagination [paginationForm]="paginationForm"></app-pagination>
</div>
<ng-template #loader>
  <div class="flex justify-center h-full items-center">
    <app-loader class="max-w-[200px]"></app-loader>
  </div>
</ng-template>

<ng-template #empty>
  <div class="text-center py-10">No matching results</div>
</ng-template>

<ng-template #podcasts>
  <ng-container *ngIf="data.length; else empty">
    <div
      class="flex items-center flex-nowrap px-2 md:px-8 py-4 cursor-pointer hover:bg-gray-200"
      *ngFor="let podcast of data; let i = index"
      [routerLink]="['play', podcast?.id]"
    >
      <div
        class="flex items-center flex-nowrap w-full max-w-[45%] md:max-w-[40%] pl-0 md:pl-4 pr-8"
      >
        <div
          class="min-w-[32px] min-h-[32px] mr-1 md:mr-3 relative flex justify-center items-center"
        >
          <img
            class="shadow-primary max-h-full max-w-full"
            podcastIcon
            [podcastUrl]="podcast.url"
          />
        </div>
        <span class="max-w-full truncate">
          {{ podcast?.title }}
        </span>
      </div>
      <span class="inline-block w-full max-w-[30%] md:max-w-[25%] pr-2">{{
        podcast.date_created | date : 'dd.MM.yyyy'
      }}</span>
      <span class="inline-block w-[22%] md:w-[30%] pr-2">{{
        steps[podcast.summary_length! - 1]
      }}</span>
      <div class="ml-8 text-base relative flex items-center">
        <button class="p-1 rounded-md hover:bg-gray-500" (click)="showPodcastMenu($event, podcast)">
          <app-icon name="row-menu"></app-icon>
        </button>
        <app-podcast-menu
          class="absolute right-6"
          [ngClass]="data.length > 3 && i > data.length - 3 ? '-bottom-1' : '-top-1'"
          *ngIf="selectedPodcast()?.id === podcast?.id"
          (menuActionEmitter)="onPodcastMenuAction($event)"
        ></app-podcast-menu>
      </div>
    </div>
  </ng-container>
</ng-template>
