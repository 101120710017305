import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonComponent, ButtonStyleClassEnum } from '../../button/button.component';
import { CustomOverlayRef } from '../../../../core/services/overlay.service';

@Component({
  selector: 'app-modal-dialog-buttons',
  standalone: true,
  templateUrl: './modal-dialog-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent],
})
export class ModalDialogButtonsComponent {
  @Input() rightButtonType: 'submit' | 'button' = 'button';
  @Input() leftButtonText!: string;
  @Input() leftButtonValue!: any;
  @Input() rightButtonText!: string;
  @Input() rightButtonValue!: any;

  readonly buttonStyleClassEnum = ButtonStyleClassEnum;

  constructor(public readonly dialogRef: CustomOverlayRef) {}

  onRightButton() {
    this.rightButtonType === 'button' && this.dialogRef.close(this.rightButtonValue || true);
  }
}
