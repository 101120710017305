import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MutationService, QueryService } from '@ngneat/query';
import { IPodcast } from './library.types';
import { environment } from '../../../../../../environments/environment';
import { IOutput } from '../../../../auth/state/auth.types';

@Injectable()
export class LibraryHttpService {
  constructor(
    private _httpClient: HttpClient,
    private _mutationService: MutationService,
    private readonly _queryService: QueryService
  ) {}

  queryPodcastsList(filters: string) {
    return this._queryService.use(
      ['podcastsList'],
      () => {
        return this._httpClient.get<IOutput<IPodcast[]>>(
          `${environment.apiUrl}/items/library?fields=id,date_created,title,summary_length,url&${filters}&meta=*&filter[removed][_eq]=false`
        );
      },
      {
        refetchOnWindowFocus: false,
      }
    );
  }

  deletePodcast() {
    return this._mutationService.use((id: string) => {
      return this._httpClient.patch<any>(`${environment.apiUrl}/items/library/${id}`, {
        removed: true,
      });
    });
  }

  renamePodcast() {
    return this._mutationService.use((data: Partial<IPodcast>) => {
      return this._httpClient.patch<any>(`${environment.apiUrl}/items/library/${data.id}`, {
        title: data.title,
      });
    });
  }
}
