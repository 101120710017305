import { Injectable } from '@angular/core';
import { AudioPlayerForm } from '../components/player/forms/audio-player.form';
import { PlayingHttpService } from '../../modules/dashboard/modules/library/state/playing-http.service';
import { IPodcast } from '../../modules/dashboard/modules/library/state/library.types';
import { AudioService } from '../components/player/services/audio.service';

@Injectable({ providedIn: 'root' })
export class PodcastDataService {
  podcastMutation = this._playingHttpService.mutatePodcastData();
  constructor(
    private readonly _audioPlayerForm: AudioPlayerForm,
    private readonly _playingHttpService: PlayingHttpService,
    private readonly _audioService: AudioService
  ) {}

  private _updatePodcastData(data: Partial<IPodcast>) {
    if (!this._audioService.podcast$.value?.id) return;
    this.podcastMutation.mutate({ id: this._audioService.podcast$.value?.id, ...data });
  }

  savePodcastData(type: 'TIME' | 'SPEED' | 'FINISHED', data?: Partial<IPodcast>) {
    switch (type) {
      case 'TIME':
        return this._updatePodcastData({ user_time: this._audioPlayerForm.vm.getRawValue().audio });
      case 'SPEED':
        return this._updatePodcastData({ ...data });
      case 'FINISHED':
        return this._updatePodcastData({ finished: true });
    }
  }
}
