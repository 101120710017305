<div
  class="relative w-full min-h-full min-w-[100px] px-[30px] pt-[80px] pb-[50px] flex flex-col bg-white rounded-[20px]"
  [ngClass]="{
    'border-2': theme === 'SETTINGS',
    'border-gray-500': index === 1,
    'gradient-border': index === 0
  }"
>
  <span class="text-xl font-medium pb-2.5">{{ data.name }}</span>

  <span class="text-5xl font-bold"
    >${{ (data.mode === 'month' ? data.price : data.price / 12) / 100 }} /month</span
  >

  <div class="flex items-center gap-[11px] mt-[7px]">
    <span class="text-sm font-medium">{{ data.description }}</span>

    <span class="text-sm px-2.5 py-1 bg-font-primary text-white rounded font-semibold"
      >billed {{ data.mode === 'month' ? 'monthly' : 'annually' }}
    </span>
  </div>

  <div class="mb-4 inner-html-content" [innerHTML]="data.content || '' | safe : 'html'"></div>

  <button
    class="relative block z-10 mt-auto w-full p-2.5 bg-primary-500 text-white rounded-[30px] text-xl font-medium cursor-pointer text-center"
    (click)="onSubscriptionPlanClick(theme === 'SETTINGS' ? data.stripe_price_id : '')"
  >
    {{ theme === 'SETTINGS' && freeTrialUnavailable ? 'Choose' : data.btn_text || 'Try for free' }}
  </button>
</div>
