import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[ngVar]',
  standalone: true,
})
export class NgVarDirective {
  private _isTemplateCreated = false;
  private readonly _context: { ngVar: any } = { ngVar: null };

  @Input()
  set ngVar(value: any) {
    this.setVariable(value);
  }

  constructor(
    private readonly _vcRef: ViewContainerRef,
    private readonly _templateRef: TemplateRef<{ ngVar: any }>
  ) {}

  private createView(): void {
    this._vcRef.clear();
    this._vcRef.createEmbeddedView(this._templateRef, this._context);
  }

  private setVariable(value: any): void {
    this._context.ngVar = value;
    if (!this._isTemplateCreated) {
      this.createView();
      this._isTemplateCreated = true;
    }
  }
}
