<section class="h-full flex flex-col" *ngVar="podcastSummaryQuery$ | async as vm">
  <div
    *ngIf="vm?.status !== 'success' || vm.isRefetching; else podcastSummary"
    class="flex justify-center items-center flex-grow"
    [ngSwitch]="vm?.status"
  >
    <app-loader *ngSwitchDefault />
    <app-info *ngSwitchCase="'error'" />
  </div>

  <ng-template #podcastSummary>
    <app-playing-nav
      [voice]="vm?.data?.voice"
      [summaryLength]="vm?.data?.podcast.summary_length"
    ></app-playing-nav>
    <app-playing-article-summary [podcast]="vm?.data?.podcast"></app-playing-article-summary>
  </ng-template>
</section>
