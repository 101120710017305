import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalCloseComponent } from '../../../../shared/components/modal/modal-close/modal-close.component';
import { CustomOverlayRef } from '../../../../core/services/overlay.service';
import {
  ButtonComponent,
  ButtonStyleClassEnum,
} from '../../../../shared/components/button/button.component';
import { ModalDialogButtonsComponent } from '../../../../shared/components/modal/modal-dialog-buttons/modal-dialog-buttons.component';

@Component({
  selector: 'app-modal-logout',
  standalone: true,
  templateUrl: './modal-logout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ModalCloseComponent, ButtonComponent, ModalDialogButtonsComponent],
})
export class ModalLogoutComponent {
  constructor(public readonly dialogRef: CustomOverlayRef) {}

  protected readonly ButtonStyleClassEnum = ButtonStyleClassEnum;
}
