<section class="flex mb-8 md:mb-[90px] h-[60px]">
  <button
    routerLink="/app/library"
    class="w-8 h-8 bg-gray-200 rounded-full hover:bg-gray-500 mt-1.5"
  >
    <app-icon name="arrow-left" class="text-3xl"></app-icon>
  </button>
  <div
    class="flex ml-5 md:ml-[70px] gap-5 flex-nowrap w-[calc(100%-55px)] md:w-full overflow-x-auto h-full"
  >
    <div
      class="flex items-center py-3 px-4 rounded-full border-[1px] border-gray-500 bg-gray-200 text-sm h-[45px]"
    >
      <div class="pr-2 mr-2 border-r-[1px] border-black" *ngIf="voice.languageIco">
        <img
          class="min-w-[20px] w-[20px] h-[20px] rounded-full shadow-secondary"
          [src]="voice.languageIco"
        />
      </div>
      <span> {{ voice.languageCode | languageName }} </span>
    </div>

    <div
      class="flex items-center py-3 px-4 rounded-full border-[1px] border-gray-500 bg-gray-200 text-sm h-[45px]"
    >
      <div class="pr-2 mr-2 border-r-[1px] border-black">
        <app-icon name="voice" class="text-xl"></app-icon>
      </div>
      <span>{{ voice.displayName }}</span>
    </div>

    <div
      class="flex items-center py-3 px-4 rounded-full border-[1px] border-gray-500 bg-gray-200 text-sm h-[45px]"
    >
      <div class="pr-2 mr-2 border-r-[1px] border-black">
        <app-icon name="speed-icon" class="text-xl"></app-icon>
      </div>
      <span> {{ steps[summaryLength - 1] }} </span>
    </div>
  </div>
</section>
