import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-pagination-button',
  standalone: true,
  templateUrl: './pagination-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass],
})
export class PaginationButtonComponent {
  @Input() disabled = false;
  @Input() active = false;
}
