<label *ngIf="label" [for]="id" class="text-sm pb-2 block">{{ label }}</label>

<div class="relative">
  <input
    [id]="id"
    [disabled]="disabled"
    [type]="inputType"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    (blur)="blur.emit()"
    (focus)="focus.emit()"
    class="w-full rounded-lg border text-black placeholder-black bg-white px-[20px] py-[10px] text-[15px]"
    [ngClass]="{
      'pr-[40px]': type === 'password',
      'pl-[45px]': showLeftIcon,
      '!rounded-[30px]': dashboardInput,
      'border-gray-500': !error,
      'border-error': error
    }"
  />

  <div
    *ngIf="type === 'password'"
    class="absolute right-[20px] top-0 h-full flex items-center text-[18px]"
  >
    <app-icon
      *ngIf="isPasswordVisible()"
      name="visibility-on"
      (click)="isPasswordVisible.set(!isPasswordVisible())"
      class="cursor-pointer"
    ></app-icon>

    <app-icon
      *ngIf="!isPasswordVisible()"
      name="visibility-off"
      (click)="isPasswordVisible.set(!isPasswordVisible())"
      class="cursor-pointer"
    ></app-icon>
  </div>

  <div *ngIf="showLeftIcon" class="absolute left-[20px] top-0 h-full flex items-center text-[15px]">
    <app-icon name="search"></app-icon>
  </div>
</div>

<div *ngIf="showError" class="text-[12px] py-1 text-error">{{ error }}</div>
