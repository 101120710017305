import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { filterSuccess, MutationService, QueryService } from '@ngneat/query';
import { IPodcast, IVoiceData } from './library.types';
import { environment } from '../../../../../../environments/environment';
import { IOutput } from '../../../../auth/state/auth.types';
import { map, switchMap, takeLast, tap } from 'rxjs';
import { AudioService } from '../../../../../shared/components/player/services/audio.service';
import { GetPodcastModeEnum } from '../../../../../shared/components/player/player.component';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PlayingHttpService {
  constructor(
    private _httpClient: HttpClient,
    private readonly _queryService: QueryService,
    private readonly _mutationService: MutationService,
    private readonly _audioService: AudioService
  ) {}

  podcastSummaryQuery(id: string) {
    let podcastData: IPodcast;
    return this._queryService.use(
      ['podcastSummary'],
      () => {
        return this._httpClient
          .get<IOutput<IPodcast>>(
            `${environment.apiUrl}/items/library/${id}?fields=id,date_created,title,url,voice,summary_length,playback_speed,content,audio,user_time,finished,author`
          )
          .pipe(
            filter(value => !!value),
            switchMap(value => {
              podcastData = value.data;

              podcastData.content = podcastData.content?.replace(/\n/g, '<br>');

              return this.getVoice(podcastData.voice);
            }),
            map(value => {
              value.data.languageIco = environment.apiUrl + '/assets/' + value.data.languageIco;

              return {
                podcast: { ...podcastData },
                voice: { ...value.data },
              };
            }),
            tap(() => this._setAudioSource(podcastData))
          );
      },
      {
        refetchOnWindowFocus: false,
      }
    );
  }

  getVoice(id: string | undefined) {
    return this._httpClient.get<IOutput<IVoiceData>>(`${environment.apiUrl}/items/voices/${id}`);
  }

  getNewPodcast(mode: GetPodcastModeEnum, currentPodcastId: string) {
    return this._queryService.use(
      ['audioPlayerPodcast'],
      () => {
        return this._httpClient
          .get<IOutput<Partial<IPodcast>[]>>(
            `${environment.apiUrl}/items/library?fields=id&sort=-date_created&filter[removed][_eq]=false`
          )
          .pipe(
            map(value => {
              return this._getNewPodcastId(value.data, currentPodcastId, mode) || '';
            }),
            switchMap((newPodcastId: string) => {
              return this.podcastSummaryQuery(newPodcastId).result$.pipe(
                filterSuccess(),
                takeLast(1)
              );
            })
          );
      },
      {
        refetchOnWindowFocus: false,
      }
    );
  }
  private _getNewPodcastId(
    ids: Partial<IPodcast>[],
    currentPodcastId: string,
    mode: GetPodcastModeEnum
  ) {
    let newPodcastId;

    ids.find((podcast, index) => {
      if (podcast?.id === currentPodcastId) {
        switch (mode) {
          case GetPodcastModeEnum.NEXT:
            return (newPodcastId = index + 1 < ids.length ? ids[index + 1].id : ids[0].id);
          case GetPodcastModeEnum.PREVIOUS:
            return (newPodcastId = index - 1 >= 0 ? ids[index - 1].id : ids[ids.length - 1].id);
          case GetPodcastModeEnum.SHUFFLE:
            const podcastsWithoutCurrentId = ids.filter(podcast => podcast.id !== currentPodcastId);
            const randomIndex = Math.floor(Math.random() * podcastsWithoutCurrentId.length);

            return (newPodcastId = podcastsWithoutCurrentId[randomIndex].id);
        }
      }

      return;
    });

    return newPodcastId;
  }

  mutatePodcastData() {
    return this._mutationService.use((data: Partial<IPodcast>) => {
      return this._httpClient.patch<IOutput<IPodcast>>(
        `${environment.apiUrl}/items/library/${data.id}`,
        data
      );
    });
  }

  private _setAudioSource(podcastData: IPodcast) {
    if (!podcastData.audio || this._audioService.podcast$.value?.id === podcastData.id) return;

    podcastData.audio = 'data:audio/aac;base64, ' + podcastData.audio;

    this._audioService.setAudio({ ...podcastData });
  }
}
