<div class="flex flex-col-reverse md:flex-row">
  <app-button
    (click)="dialogRef.close(leftButtonValue)"
    [styleClass]="buttonStyleClassEnum.CANCEL"
    class="w-full md:w-1/2 md:max-w-[200px] md:mr-5"
    >{{ leftButtonText || 'Cancel' }}</app-button
  >
  <app-button
    [type]="rightButtonType"
    (click)="onRightButton()"
    class="w-full md:w-1/2 md:max-w-[200px] mb-3 md:mb-0"
    >{{ rightButtonText }}</app-button
  >
</div>
