<ng-container *ngIf="currentUserQuery$.result$ | async as currentUserQuery">
  <section
    class="max-w-[450px]"
    *ngIf="currentUserQuery.status === 'success' && !currentUserQuery.isFetching"
  >
    <div *ngVar="currentUserQuery.data.data as userData">
      <h2 class="text-xl mb-[30px]">Login details</h2>

      <div class="text-sm mb-[30px]">
        <span class="block mb-[10px]">Email: {{ userData?.email }}</span>
        <a (click)="changeUserData('email')" class="text-[#4285F4]">Change email address</a>
      </div>

      <div class="text-sm mb-[50px]">
        <span class="block mb-[10px]">Password: {{ userData?.password }}</span>
        <a (click)="changeUserData('password')" class="text-[#4285F4]">Change password</a>
      </div>

      <div>
        <h2 class="text-xl mb-5">Delete your account</h2>

        <p class="text-sm mb-[10px]">
          Deleting your account will delete all your content and remove all data associated with it.
          Please note it will not end a purchased subscription.
        </p>
        <p class="text-sm mb-[30px]">
          Before you can delete your account, your active subscription must be canceled.
        </p>

        <a (click)="checkSubscriptionStatus(userData?.email)" class="text-error text-sm"
          >Delete account</a
        >
      </div>
    </div>
  </section>

  <div
    class="flex justify-center h-full mt-[30%]"
    *ngIf="currentUserQuery.status !== 'success' || currentUserQuery.isFetching"
  >
    <app-info *ngIf="currentUserQuery.status === 'error'; else loader"></app-info>
  </div>
</ng-container>
<ng-template #loader>
  <app-loader class="max-w-[200px]"></app-loader>
</ng-template>
