import { Routes } from '@angular/router';
import { HomeLayoutComponent } from './_layout/layout';
import { EmailConfirmGuard } from '../../core/guards/email-confirm.guard';
import { authGuard } from '../../core/guards/auth.guard';
import { BLOG_ROUTES } from '../blog/blog-routes';

export const HOME_ROUTES: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        canMatch: [authGuard({ isProtected: false, redirectTo: ['/app/home'] })],
        loadComponent: () => import('./pages/home-page/home-page').then(x => x.HomePage),
      },
      {
        path: 'terms-of-service',
        loadComponent: () =>
          import('./pages/static-page/static-page.page').then(x => x.StaticPagePage),
        data: {
          slug: 'terms-of-use',
          title: 'Terms & Conditions',
          subtitle: '',
        },
      },
      {
        path: 'privacy-policy',
        loadComponent: () =>
          import('./pages/static-page/static-page.page').then(x => x.StaticPagePage),
        data: {
          slug: 'privacy-policy',
          title: 'Privacy Policy',
          subtitle: '',
        },
      },
      {
        path: 'pricing',
        canMatch: [authGuard({ isProtected: false, redirectTo: ['/app/home'] })],
        loadComponent: () =>
          import('./pages/pricing-page/pricing-page.component').then(x => x.PricingPageComponent),
      },
      {
        path: 'email-confirm',
        canActivate: [EmailConfirmGuard()],
        loadComponent: () => import('./pages/home-page/home-page').then(x => x.HomePage),
      },
      { path: 'blog', children: BLOG_ROUTES },
    ],
  },
];
