<ng-container *ngVar="mode === 'player' as player">
  <div class="relative" [ngClass]="{ player: player }">
    <input
      type="range"
      #rangeInput
      [step]="step"
      [min]="minCount"
      [max]="maxCount"
      [(ngModel)]="value"
      (change)="onManualChange(value)"
      (mousedown)="mouseDownEmitter.emit()"
      (mouseup)="mouseUpEmitter.emit()"
    />
    <div
      class="absolute text-white flex justify-center w-[65px] -top-6 -translate-x-[25px]"
      #tooltip
      *ngIf="!player"
    >
      <img src="assets/icons/tooltip-bg-2.svg" />
      <span class="absolute top-0 text-xs pt-0.5">{{ steps[value - 1] }}</span>
    </div>
  </div>

  <div class="relative h-[4px] flex justify-between px-1.5 mt-1 mb-4" *ngIf="!player">
    <div *ngFor="let mark of marksArray; let i = index" class="h-full">
      <div class="h-full w-[1px] bg-gray-700"></div>
      <span class="absolute -translate-x-1/2 text-gray-700 text-[10px] md:text-xs">{{
        steps[i]
      }}</span>
    </div>
  </div>
</ng-container>

<!--I'm saving it for the future should the numbers come back-->
<!--<span class="absolute top-0 text-xs pt-0.5">{{ value }} words</span>-->
<!--<span class="absolute -translate-x-1/2 text-gray-700 text-[10px]">{{ (i + 1) * step }}</span>-->
