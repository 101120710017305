import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IconComponent } from '../../../../../../shared/components/icon/icon.component';
import { ButtonComponent } from '../../../../../../shared/components/button/button.component';
import {
  AsyncPipe,
  DatePipe,
  NgForOf,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
} from '@angular/common';
import { OverlayService } from '../../../../../../core/services/overlay.service';
import { ModalDeleteSubscriptionComponent } from '../../components/modal-delete-subscription/modal-delete-subscription.component';
import { Router, RouterLink } from '@angular/router';
import { PricingService } from '../../state/pricing.service';
import { LoaderComponent } from '../../../../../../shared/components/loader/loader.component';
import { InfoComponent } from '../../../../../../shared/components/error-info/info.component';

@Component({
  selector: 'app-subscription',
  standalone: true,
  templateUrl: './subscription.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    IconComponent,
    ButtonComponent,
    AsyncPipe,
    NgIf,
    RouterLink,
    LoaderComponent,
    InfoComponent,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    NgForOf,
    DatePipe,
  ],
})
export class SubscriptionComponent {
  subscriptionQuery = this._pricingService.getActiveUserPlan();
  invoicesQuery$ = this._pricingService.getInvoices().result$;
  unsubscribeMutation$ = this._pricingService.deletePlan();

  constructor(
    private readonly _overlayService: OverlayService,
    private readonly _pricingService: PricingService,
    private readonly _router: Router
  ) {}

  deleteSubscriptionPlan(stripePlanId: string) {
    const overlayRef = this._overlayService.open(
      { position: 'cc' },
      ModalDeleteSubscriptionComponent
    );

    overlayRef.closedEvent.subscribe(value => {
      value &&
        this.unsubscribeMutation$.mutate(stripePlanId).then(() => {
          this.subscriptionQuery.refetch();
          this._router.navigate(['/app/stripe-subscription-cancelled']);
        });
    });
  }

  onChangePlanButton() {
    this._router.navigate(['app/settings/change-plan']);
  }
}
