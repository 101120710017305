import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class PageTitleService {
  constructor(private readonly _titleService: Title) {}

  setTitle(title: string) {
    this._titleService.setTitle(title);
  }
}
