import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { MutationService, QueryService } from '@ngneat/query';
import { IOutput } from '../../../../auth/state/auth.types';
import { environment } from '../../../../../../environments/environment';
import { INTERCEPT_ERRORS } from '../../../../../core/interceptors/auth.interceptor';
import { IPrice } from '../../../../../utils/pricing.types';
import { forkJoin, map, of, switchMap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IActiveUserPlan, ISession, ISubscriptionDetails } from './profile.types';

@Injectable({ providedIn: 'root' })
export class PricingService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _queryService: QueryService,
    private readonly _mutationService: MutationService
  ) {}

  getPricing() {
    return this._queryService.use(
      ['pricing'],
      () => {
        return this._httpClient.get<IOutput<IPrice[]>>(
          `${environment.apiUrl}/items/premium_plans?fields=id,name,description,stripe_product_id,stripe_price_id,price,mode,btn_text,content`,
          {
            context: new HttpContext().set(INTERCEPT_ERRORS, false),
          }
        );
      },
      {
        refetchOnWindowFocus: false,
      }
    );
  }

  getPaymentSessionUrl() {
    return this._mutationService.use((priceId: string) => {
      return this._httpClient.post<ISession>(
        `${environment.apiUrl}/stripe-open-checkout`,
        { priceId },
        { context: new HttpContext().set(INTERCEPT_ERRORS, false) }
      );
    });
  }

  getActiveUserPlan() {
    let activePlans: Partial<IActiveUserPlan[]> | null;

    return this._queryService.use(
      ['userSubscription'],
      () => {
        return this._httpClient
          .get<IOutput<Partial<IActiveUserPlan[]>>>(
            `${environment.apiUrl}/items/user_subscriptions?&filter[isActive][_eq]=true`,
            {
              context: new HttpContext().set(INTERCEPT_ERRORS, false),
            }
          )
          .pipe(
            filter(value => !!value.data),
            switchMap(value => {
              activePlans = value.data || null;

              if (!activePlans?.length) return of([]);

              return forkJoin(
                activePlans?.map(activePlan => {
                  return activePlan?.plan ? this.getPlanDetails(activePlan.plan) : of(null);
                })
              );
            }),
            map(data => {
              if (!data?.length) return [];

              return data.map((planDetails, i) => {
                return {
                  ...planDetails?.data,
                  id: activePlans?.[i]?.id,
                  stripe_subscription_id: activePlans?.[i]?.stripe_subscription_id,
                };
              });
            })
          );
      },
      {
        refetchOnWindowFocus: false,
        keepPreviousData: false,
      }
    );
  }

  getPlanDetails(planId: string) {
    return this._httpClient
      .get<IOutput<Partial<IActiveUserPlan>>>(
        `${environment.apiUrl}/items/premium_plans/${planId}?fields=name,id,limit,max_output_words`,
        {
          context: new HttpContext().set(INTERCEPT_ERRORS, false),
        }
      )
      .pipe(
        switchMap(plans => {
          return this._httpClient
            .get<IOutput<Partial<ISubscriptionDetails>>>(`${environment.apiUrl}/subscription`)
            .pipe(
              map(details => {
                return {
                  data: { ...plans.data, subscriptionDetails: details.data },
                };
              })
            );
        })
      );
  }

  getInvoices() {
    return this._queryService.use(
      ['invoices'],
      () => {
        return this._httpClient.get<{ portalUrl: string }>(
          `${environment.apiUrl}/stripe-open-checkout/portal-session`,
          {
            context: new HttpContext().set(INTERCEPT_ERRORS, false),
          }
        );
      },
      { retry: false }
    );
  }

  deletePlan() {
    return this._mutationService.use((stripeSubscriptionId: string) => {
      return this._httpClient.post(`${environment.apiUrl}/stripe-open-checkout/cancel`, {
        stripeSubscriptionId,
      });
    });
  }
}
