<div
  class="w-full overflow-x-hidden bg-white flex min-h-screen"
  *ngVar="activePodcast$ | async as showPlayer"
>
  <div class="flex flex-grow">
    <app-menu></app-menu>
    <div [ngClass]="{'pb-[185px] pm:pb-[100px]': showPlayer}">
      <router-outlet />
    </div>
    <app-player class="w-screen fixed bottom-0 mr-[100px] z-10"></app-player>
  </div>
</div>
