import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SubscriptionOptionsComponent } from '../../../../../home/components/subscription-options/subscription-options.component';
import { IconComponent } from '../../../../../../shared/components/icon/icon.component';
import { RouterLink } from '@angular/router';
import { PricingService } from '../../state/pricing.service';
import { NgVarDirective } from '../../../../../../shared/directives/ng-var.directive';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'app-change-pricing-plan',
  standalone: true,
  templateUrl: './change-pricing-plan.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SubscriptionOptionsComponent,
    IconComponent,
    RouterLink,
    NgVarDirective,
    AsyncPipe,
    NgIf,
  ],
})
export class ChangePricingPlanComponent {
  invoicesQuery$ = this._pricingService.getInvoices().result$;

  constructor(private readonly _pricingService: PricingService) {}
}
