import { Routes } from '@angular/router';
import { SettingsLayoutComponent } from './_layout/layout';
import { SubscriptionComponent } from './pages/subscription/subscription.component';
import { PersonalInformationComponent } from './pages/personal-information/personal-information.component';
import { ChangePricingPlanComponent } from './pages/change-pricing-plan/change-pricing-plan.component';

export const SETTINGS_ROUTES: Routes = [
  {
    path: '',
    component: SettingsLayoutComponent,
    children: [
      { path: '', component: SubscriptionComponent },
      { path: 'personal-information', component: PersonalInformationComponent },
      {
        path: 'change-plan',
        component: ChangePricingPlanComponent,
        data: {
          elementsToHide: ['settingsNav'],
        },
      },
      { path: '**', redirectTo: '' },
    ],
  },
];
