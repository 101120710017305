import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PageTitleService } from '../../../../../shared/services/page-title.service';

@Component({
  standalone: true,
  selector: 'iw-app-dashboard-home-layout',
  templateUrl: './layout.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet],
})
export class DashboardHomeLayout implements OnInit {
  constructor(private readonly _pageTitleService: PageTitleService) {}

  ngOnInit() {
    this._pageTitleService.setTitle('Home - iListen');
  }
}
