import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomOverlayRef } from '../../../../../../core/services/overlay.service';
import {
  ButtonComponent,
  ButtonStyleClassEnum,
} from '../../../../../../shared/components/button/button.component';
import { ModalCloseComponent } from '../../../../../../shared/components/modal/modal-close/modal-close.component';
import {
  ModalDialogButtonsComponent
} from "../../../../../../shared/components/modal/modal-dialog-buttons/modal-dialog-buttons.component";

@Component({
  selector: 'app-modal-delete-subscription',
  standalone: true,
  templateUrl: './modal-delete-subscription.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, ModalCloseComponent, ModalDialogButtonsComponent],
})
export class ModalDeleteSubscriptionComponent {
  readonly ButtonStyleClassEnum = ButtonStyleClassEnum;
  constructor(public dialogRef: CustomOverlayRef) {}
}
