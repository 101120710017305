<h3 class="text-center mt-4 text-[32px] font-Merriweather mb-12">My Library</h3>

<div class="flex gap-[10px] mb-8">
  <app-button
    *ngFor="let button of filterButtons"
    [styleClass]="
      filterControl.value === button.value
        ? ButtonStyleClassEnum.REGULAR
        : ButtonStyleClassEnum.UNSELECTED
    "
    (click)="changeSortType(button.value)"
    class="w-[100px]"
    ><span class="text-sm">{{ button.name }}</span></app-button
  >
</div>
