import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InputSelectComponent } from '../../../../../../shared/components/input-select/input-select.component';
import { IconComponent } from '../../../../../../shared/components/icon/icon.component';
import { IVoiceData } from '../../state/library.types';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { RouterLink } from '@angular/router';
import { LanguagePipe } from '../../../../../../shared/pipes/language.pipe';
import { STEPS } from '../../../../../../utils/slider-steps';

@Component({
  selector: 'app-playing-nav',
  standalone: true,
  templateUrl: './playing-nav.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InputSelectComponent,
    IconComponent,
    NgForOf,
    NgTemplateOutlet,
    RouterLink,
    NgIf,
    LanguagePipe,
  ],
})
export class PlayingNavComponent {
  @Input() voice!: IVoiceData;
  @Input() summaryLength!: number;

  steps = STEPS;
}
