/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Component, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { getErrorMessage } from '../../utils/validators';

@Component({
  selector: 'app-input-base',
  template: '',
  host: { '(blur)': 'onTouch()' },
})
export class InputBaseComponent<T> implements ControlValueAccessor {
  protected innerValue!: T;
  disabled = false;

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  get error() {
    if (
      this.ngControl.control?.touched &&
      this.ngControl.control?.invalid &&
      this.ngControl.control?.errors
    ) {
      return getErrorMessage(this.ngControl.control.errors);
    }

    return null;
  }

  get value() {
    return this.innerValue;
  }

  set value(value: T) {
    this.writeValue(value);
    this.onChange(value);
    this.onTouch(value);
  }

  writeValue(value: T) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: () => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  onChange: any = () => {};
  onTouch: any = () => {};
}
