import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputBaseComponent } from '../../../core/bases/input-base.component';
import { IconComponent } from '../icon/icon.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styles: [':host { display: block }'],
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [FormsModule, ReactiveFormsModule, IconComponent, NgIf, NgClass],
})
export class InputTextComponent extends InputBaseComponent<string> {
  @Input() id!: string;
  @Input() type = 'text';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() showError = true;
  @Input() showLeftIcon = false;
  @Input() dashboardInput = false;
  @Output() focus: EventEmitter<FocusEvent> = new EventEmitter();
  @Output() blur: EventEmitter<FocusEvent> = new EventEmitter();

  isPasswordVisible = signal(false);

  get inputType() {
    if (this.type === 'password') {
      return this.isPasswordVisible() ? 'text' : 'password';
    }

    return this.type;
  }
}
