import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CurrencyPipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { Router } from '@angular/router';
import { IPrice } from '../../../../utils/pricing.types';
import { SafePipe } from '../../../../shared/pipes/safe.pipe';

@Component({
  standalone: true,
  selector: 'app-subscription-card',
  templateUrl: './subscription-card.component.html',
  styles: [
    `
      .inner-html-content {
        strong {
          display: flex;
          align-items: center;
          gap: 4px;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NgStyle, CurrencyPipe, NgIf, SafePipe],
})
export class SubscriptionCardComponent {
  @Input() data!: IPrice;
  @Input() index!: number;
  @Input() theme: 'HOME' | 'SETTINGS' = 'HOME';
  @Input() freeTrialUnavailable = true;

  @Output() planClickEmitter = new EventEmitter<string>();

  constructor(private readonly _router: Router) {}

  onSubscriptionPlanClick(priceId?: string) {
    priceId ? this.planClickEmitter.emit(priceId) : this._router.navigate(['/auth/register']);
  }
}
