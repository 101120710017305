import { Injectable } from '@angular/core';
import { DashboardHomeHttpService } from './dashboard-home-http.service';
import { distinctUntilChanged, switchMap } from 'rxjs';
import { GeneratePodcastForm } from '../forms/generate-podcast.form';
import { IGeneratePodcastData } from './dashboard-home.types';

@Injectable()
export class DashboardHomeService {
  constructor(
    private readonly _dashboardHomeHttpService: DashboardHomeHttpService,
    private readonly _generatePodcastForm: GeneratePodcastForm
  ) {}

  getLanguages() {
    return this._dashboardHomeHttpService.getLanguages().result$;
  }

  languageSelectListener() {
    return this._generatePodcastForm.vm.controls.language.valueChanges.pipe(
      distinctUntilChanged(),
      switchMap(data => this._getVoices(`${data?.value}`))
    );
  }

  private _getVoices(languageCode: string) {
    return this._dashboardHomeHttpService.getVoices(languageCode).result$;
  }

  prepareDataToGeneratePodcast(data: any): IGeneratePodcastData {
    return {
      url: data.url,
      voice: data.voice.value,
      playback_speed: data.playback_speed.value,
      summary_length: data.summary_length,
    };
  }
}
