import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';

export enum ButtonStyleClassEnum {
  SOCIAL,
  REGULAR,
  CANCEL,
  UNSELECTED,
}

@Component({
  standalone: true,
  selector: 'app-button',
  templateUrl: './button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NgIf],
})
export class ButtonComponent {
  @Input() id!: string;
  @Input() type: 'button' | 'submit' = 'button';
  @Input() disabled!: boolean | undefined;
  @Input() fullWidth!: boolean;
  @Input() styleClass: ButtonStyleClassEnum = ButtonStyleClassEnum.REGULAR;

  @HostBinding('class') get classes(): string {
    return this._prepareButtonClassNames();
  }

  private _prepareButtonClassNames() {
    let classNames = (this.fullWidth ? 'block w-full' : 'inline-block') + ' ';

    if (this.disabled) {
      classNames += 'bg-gray-500 text-gray-600';
    } else {
      switch (this.styleClass) {
        case ButtonStyleClassEnum.CANCEL:
          classNames += 'bg-white text-primary-500 border-primary-500 border-[1px]';
          break;
        case ButtonStyleClassEnum.SOCIAL:
          classNames += 'bg-[#4285F4] text-white';
          break;
        case ButtonStyleClassEnum.UNSELECTED:
          classNames += 'bg-white text-black border-gray-500 border-[1px]';
          break;
        default:
          classNames += 'bg-primary-500 text-white';
          break;
      }
    }

    classNames += ' rounded-[30px]';

    return classNames;
  }
}
