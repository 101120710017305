import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgForOf } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';

export interface Link {
  name: string;
  url: string;
}

const LINKS: Link[] = [
  {
    name: 'Subscription',
    url: '',
  },
  {
    name: 'Personal information',
    url: '/personal-information',
  },
];

@Component({
  selector: 'app-settings-nav',
  standalone: true,
  imports: [NgForOf, RouterLink, RouterLinkActive],
  templateUrl: './settings-nav.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsNavComponent {
  links = LINKS;
}
