import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { OverlayService } from '../../../../../../core/services/overlay.service';
import { ModalDeleteAccountComponent } from '../../components/modal-delete-account/modal-delete-account.component';
import { AuthService } from '../../../../../auth/state/auth.service';
import { EMPTY, Subject, switchMap, takeUntil } from 'rxjs';
import { AuthRepository } from '../../../../../auth/state/auth.repository';
import { AsyncPipe, NgIf } from '@angular/common';
import { ModalChangeEmailAddressComponent } from '../../components/modal-change-email-address/modal-change-email-address.component';
import { ProfileService } from '../../state/profile.service';
import { filter, take } from 'rxjs/operators';
import { ModalChangePasswordComponent } from '../../components/modal-change-password/modal-change-password.component';
import { InfoComponent } from '../../../../../../shared/components/error-info/info.component';
import { LoaderComponent } from '../../../../../../shared/components/loader/loader.component';
import { NgVarDirective } from '../../../../../../shared/directives/ng-var.directive';
import { PricingService } from '../../state/pricing.service';
import { filterSuccess } from '@ngneat/query';
import { ModalActiveSubscriptionComponent } from '../../components/modal-active-subscription/modal-active-subscription.component';
import { Router } from '@angular/router';
import { formatISO } from 'date-fns';

@Component({
  selector: 'app-personal-information',
  standalone: true,
  templateUrl: './personal-information.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, NgIf, InfoComponent, LoaderComponent, NgVarDirective],
})
export class PersonalInformationComponent implements OnDestroy {
  updateUserMutation = this._profileService.updateProfile();
  currentUserQuery$ = this._authService.queryCurrentUser();
  subscriptionQuery$ = this._pricingService.getActiveUserPlan().result$;

  private readonly _destroy$ = new Subject<boolean>();

  constructor(
    private readonly _overlayService: OverlayService,
    private readonly _profileService: ProfileService,
    private readonly _authService: AuthService,
    private readonly _authRepository: AuthRepository,
    private readonly _pricingService: PricingService,
    private readonly _router: Router
  ) {}

  changeUserData(type: 'email' | 'password') {
    const overlayRef = this._overlayService.open(
      { position: 'cc' },
      type === 'password' ? ModalChangePasswordComponent : ModalChangeEmailAddressComponent
    );

    overlayRef.closedEvent
      .pipe(
        filter(value => !!value),
        switchMap(value => {
          return this.updateUserMutation.mutate(
            type === 'password' ? { password: value } : { email: value }
          );
        })
      )
      .subscribe(() => {
        return this._authService.queryCurrentUser().refetch();
      });
  }

  checkSubscriptionStatus(email: string) {
    this.subscriptionQuery$
      .pipe(filterSuccess(), takeUntil(this._destroy$), take(1))
      .subscribe(value =>
        value?.data?.length ? this._showActiveSubscriptionDialog() : this.deleteAccount(email)
      );
  }

  deleteAccount(email: string) {
    const overlayRef = this._overlayService.open({ position: 'cc' }, ModalDeleteAccountComponent);

    overlayRef.closedEvent
      .pipe(switchMap(value => (value ? this._authRepository.user$ : EMPTY)))
      .subscribe(
        value =>
          value &&
          this.updateUserMutation
            .mutate({ status: 'archived', email: `${formatISO(new Date())}-${email}` })
            .then(() => this._authService.logout())
      );
  }

  private _showActiveSubscriptionDialog() {
    const overlayRef = this._overlayService.open(
      { position: 'cc' },
      ModalActiveSubscriptionComponent
    );

    overlayRef.closedEvent.subscribe(value => {
      value && this._router.navigate(['/app/settings/subscription']);
    });
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
