/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthRepository } from '../../modules/auth/state/auth.repository';
import { AuthService } from '../../modules/auth/state/auth.service';
import { IOutput, IOutputLogin } from '../../modules/auth/state/auth.types';

export const INTERCEPT_ERRORS = new HttpContextToken(() => true);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private _isRefreshing = false;
  private _refreshTokenSubject: BehaviorSubject<boolean | null> = new BehaviorSubject<
    boolean | null
  >(null);

  constructor(
    private _authRepository: AuthRepository,
    private _authService: AuthService,
    private _toastService: HotToastService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this._setHeaders(request.url).pipe(
      switchMap((headers: Record<string, any>) =>
        next.handle(request.clone({ setHeaders: headers }))
      ),
      catchError((err: any) => {
        if (
          (err.status === 401 && err.error.errors[0].extensions.code === 'TOKEN_EXPIRED') ||
          (err.status === 403 && err.error.errors[0].extensions.code === 'INVALID_TOKEN')
        ) {
          return this._proceedWithRefreshToken(err, request, next);
        } else if (err.status >= 400 && err.status < 500) {
          if (err.error?.errors?.length) {
            if (
              err.error.errors[0].extensions.code === 'RECORD_NOT_UNIQUE' &&
              err.error.errors[0].extensions.field === 'email'
            ) {
              this._toastService.warning('Email already taken');
            } else if (
              err.error.errors[0].extensions.code === 'USER-CONFIRM' ||
              err.error.errors[0].extensions.code === 'LIBRARY'
            ) {
            } else {
              this._toastService.warning(err.error.errors[0].message);
            }
          } else {
            this._toastService.warning('Unknown error');
          }
        } else {
          this._toastService.error('Internal server error');
        }
        return throwError(() => err);
      })
    );
  }

  private _setHeaders(url: string): Observable<Record<string, any>> {
    return this._authRepository.token$.pipe(
      take(1),
      switchMap((token: string | null) =>
        url.includes(environment.apiUrl) && !url.includes('/auth/refresh')
          ? of({ ...(token ? { Authorization: `Bearer ${token}` } : {}) })
          : of({})
      )
    );
  }

  private _proceedWithRefreshToken(
    err: any,
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this._authRepository.refreshToken$.pipe(
      switchMap((refreshToken: string | null) => {
        return this._authRepository.token$.pipe(
          switchMap((token: string | null) => {
            if (!refreshToken) {
              if (token) {
                return this._sendGoogleRefreshTokenRequest(err, request, next);
              }

              return this._logoutUser(err);
            } else if (!this._isRefreshing) {
              return this._sendRefreshTokenRequest(err, request, next, refreshToken);
            } else {
              return this._holdRequestsWhenIsRefreshing(err, request, next);
            }
          })
        );
      })
    );
  }

  private _logoutUser(err: any): Observable<HttpEvent<any>> {
    this._authService.logout();
    return throwError(() => err);
  }

  private _sendGoogleRefreshTokenRequest(err: any, request: HttpRequest<any>, next: HttpHandler) {
    this._isRefreshing = true;
    this._refreshTokenSubject.next(null);

    return this._authService.googleRefresh().pipe(
      switchMap((response: IOutput<IOutputLogin>) => {
        this._authRepository.setToken(response.data.access_token);
        this._isRefreshing = false;
        this._refreshTokenSubject.next(true);

        return this._setHeaders(request.url).pipe(
          switchMap((headers: Record<string, any>) =>
            next.handle(request.clone({ setHeaders: headers }))
          )
        );
      }),
      catchError(() => {
        this._isRefreshing = false;
        this._refreshTokenSubject.next(false);
        return this._logoutUser(err);
      })
    );
  }

  private _sendRefreshTokenRequest(
    err: any,
    request: HttpRequest<any>,
    next: HttpHandler,
    refreshToken: string
  ): Observable<HttpEvent<any>> {
    this._isRefreshing = true;
    this._refreshTokenSubject.next(null);

    return this._authService.refreshToken(refreshToken).pipe(
      switchMap((response: IOutput<IOutputLogin>) => {
        this._authRepository.setToken(response.data.access_token);
        this._authRepository.setRefreshToken(response.data.refresh_token);
        this._isRefreshing = false;
        this._refreshTokenSubject.next(true);
        return this._setHeaders(request.url).pipe(
          switchMap((headers: Record<string, any>) =>
            next.handle(request.clone({ setHeaders: headers }))
          )
        );
      }),
      catchError(() => {
        this._isRefreshing = false;
        this._refreshTokenSubject.next(false);
        return this._logoutUser(err);
      })
    );
  }

  private _holdRequestsWhenIsRefreshing(
    err: any,
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this._refreshTokenSubject.pipe(
      filter((data: boolean | null) => data != null),
      take(1),
      switchMap((data: boolean | null) => {
        if (data) {
          return this._setHeaders(request.url).pipe(
            switchMap((headers: Record<string, any>) =>
              next.handle(request.clone({ setHeaders: headers }))
            )
          );
        } else {
          return throwError(() => err);
        }
      })
    );
  }
}
