<section class="h-full flex flex-col justify-center items-center text-center">
  <a
    class="flex gap-3 items-center py-3 px-4 rounded-full border-[1px] border-gray-500 bg-gray-200 text-sm no-underline mb-5 max-w-full md:max-w-[60%]"
    [href]="podcast.url"
    target="_blank"
  >
    <app-icon name="search"></app-icon>
    <span class="font-Merriweather text-sm truncate">{{ podcast.url }}</span>
  </a>
  <p class="text-sm text-gray-600 mb-5">
    {{ podcast.date_created | date : 'dd.MM.yyyy' }}
    {{ podcast.author ? ' | ' + podcast.author : '' }}
  </p>
  <h3 class="text-lg md:text-3xl font-Merriweather pb-8 w-full md:max-w-[70%]">
    {{ podcast.title }}
  </h3>
  <p
    class="text-gray-300 px-3 text-xl w-full md:w-[80%] max-h-[50vh] overflow-auto text-left"
    [innerHTML]="podcast.content | safe : 'html'"
  ></p>
</section>
